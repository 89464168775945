import React, { useState, useEffect } from 'react';
import { server } from '../../config';
import axios from 'axios';
import jwt_decode from "jwt-decode";
import { useNavigate } from 'react-router-dom';
import NavbarAdmin from "../Navbar/NavbarAdmin";
import './participantes-style.css';
import 'bootstrap/dist/css/bootstrap.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import '@fortawesome/fontawesome-free/css/all.min.css';

import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

function TallaCamiseta() {
    const [tallaCamiseta, setTallaCamiseta] = useState(null);
    const [user, setUser] = useState({
        userId: -1,
        name: ''
    });
    const [token, setToken] = useState('');
    const [expire, setExpire] = useState('');

    const navigate = useNavigate();

    useEffect(() => {
        document.body.classList.add("participantes-page");
        refreshToken();
        listarTallaCamiseta();
    }, []);

    const refreshToken = async () => {
        try {
            const response = await axios.get('http://' + server + '/refresh');
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            setUser({
                ...user, // Copy other fields
                userId: decoded.userId,
                name: decoded.name,
                id_tipo_acceso: decoded.id_tipo_acceso
            });
            if (decoded.id_tipo_acceso === 2) {
                navigate('/homeUser');
            }
            setExpire(decoded.exp);
        } catch (error) {
            if (error.response) {
                navigate("/");
            }
        }
    }

    const axiosJWT = axios.create();

    // Siempre que se realice una peticion segura se ejcuta esta
    // funcion que actualiza el accessToken si es necesario
    // y en config añade los headers y los datos para las queries
    axiosJWT.interceptors.request.use(async (config) => {
        const currentDate = new Date();
        if (expire * 1000 < currentDate.getTime() || expire === undefined) {
            const response = await axios.get('http://' + server + '/refresh');
            config.headers.Authorization = `Bearer ${response.data.accessToken}`;
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            setUser({
                ...user, // Copy other fields
                userId: decoded.userId,
                name: decoded.name,
                id_tipo_acceso: decoded.id_tipo_acceso
            });
            config.params = {
                userId: decoded.userId
            }
            setExpire(decoded.exp);
        } else {
            config.headers.Authorization = `Bearer ${token}`;
            config.params = {
                userId: user.userId
            }
        }
        return config;
    }, (error) => {
        return Promise.reject(error);
    });

    const listarTallaCamiseta = async () => {
        try {
            const response = await axiosJWT.get('http://' + server + '/tallaCamiseta');
            //console.log(response.data);
            setTallaCamiseta(response.data);
        } catch (err) {
            console.log(err);
        }
    };

    const eliminarTallaCamiseta = async (id_talla) => {
        try {
            await axiosJWT.post('http://' + server + '/eliminarTallaCamiseta', { id_talla: id_talla });
            EliminadoCorrectamente();
        } catch (error) {
            if (error.response) {
                console.log(error.response);
            }
            ErrorEliminando();
        }
    };

    const EliminadoCorrectamente = () => {
        confirmAlert({
            title: 'Talla Eliminada',
            message: '¡La talla ha sido eliminada con éxito!',
            buttons: [
                {
                    label: 'Cerrar',
                    onClick: () => window.location.reload()
                }
            ]
        });
    }

    const ErrorEliminando = () => {
        confirmAlert({
            title: '¡Error!',
            message: 'Ha ocurrido un error y la operación no se ha podido llevar a cabo. Vuelve a intentarlo más tarde.',
            buttons: [
                {
                    label: 'Cerrar',
                    onClick: () => window.location.reload()
                }
            ]
        });
    }

    const AvisoEliminar = (id_talla_camiseta) => {
        confirmAlert({
            title: '¡CUIDADO!',
            message: '¿Seguro que quieres eliminar este elemento?',
            buttons: [
                {
                    label: 'Si',
                    onClick: () => eliminarTallaCamiseta(id_talla_camiseta)
                },
                {
                    label: 'No',
                    onClick: () => window.location.reload()
                }
            ]
        });
    }

    return (
        <>
            <NavbarAdmin />
            {tallaCamiseta ? (
                <table>
                    <thead>
                        <tr style={{ textAlign: "center" }}>
                            <th style={{ textAlign: "center" }}>Id</th>
                            <th style={{ textAlign: "center" }}>Talla</th>
                            <th style={{ textAlign: "center" }}>Acciones</th>
                        </tr>
                    </thead>
                    <tbody>
                        {tallaCamiseta && tallaCamiseta.results && Object.values(tallaCamiseta.results).map((tallas, index) => (
                            <tr key={index}>
                            <td style={{ textAlign: "center" }}>{tallas.id_talla_camiseta}</td>
                            <td style={{ textAlign: "center" }}>{tallas.talla}</td>
                            <td style={{ textAlign: "center" }}>
                                <button type="button" onClick={() => navigate(`/editarTallaCamiseta/${tallas.id_talla_camiseta}`)} style={{ borderRadius: '20%' }}>
                                    <FontAwesomeIcon icon={faPencilAlt} />
                                </button>{" "}
                                <button type="button" onClick={() => AvisoEliminar(tallas.id_talla_camiseta)} style={{ borderRadius: '20%' }}>
                                    <FontAwesomeIcon icon={faTrashAlt} />
                                </button>
                            </td>
                          </tr>                          
                        ))}
                    </tbody>
                </table>
            ) : (
                <div>Cargando datos...</div>
            )}
        </>
    );

}

export default TallaCamiseta;
