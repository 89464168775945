import React, { useState, useEffect } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import './home-style.css';
import { useNavigate } from 'react-router-dom';
import { server } from '../../config';
import NavbarAdmin from "../Navbar/NavbarAdmin";
import NavbarUser from "../Navbar/NavbarUser";
import jwt_decode from "jwt-decode";

function HomeAdminScreen() {
  const [user, setUser] = useState({
    id_participante: -1,
    id_tipo_acceso: ''
  });
  const [token, setToken] = useState('');
  const [expire, setExpire] = useState('');

  const navigate = useNavigate();

  useEffect(() => {
    refreshToken();
    document.body.classList.add("bodyHomePage");
    return () => {
      document.body.classList.remove("bodyHomePage");
    };
  }, []);

  const refreshToken = async () => {
    try {
      const response = await axios.get('http://' + server + '/refresh');
      setToken(response.data.accessToken);
      const decoded = jwt_decode(response.data.accessToken);
      setUser({
        ...user, // Copy other fields
        id_participante: decoded.id_participante,
        id_tipo_acceso: decoded.id_tipo_acceso
      });
      setExpire(decoded.exp);
    } catch (error) {
      if (error.response) {
        navigate("/");
      }
    }
  }

  const axiosJWT = axios.create();

  // Siempre que se realice una peticion segura se ejcuta esta
  // funcion que actualiza el accessToken si es necesario
  // y en config añade los headers y los datos para las queries
  axiosJWT.interceptors.request.use(async (config) => {
    const currentDate = new Date();
    if (expire * 1000 < currentDate.getTime() || expire == undefined) {
      const response = await axios.get('http://' + server + '/refresh');
      config.headers.Authorization = `Bearer ${response.data.accessToken}`;
      setToken(response.data.accessToken);
      const decoded = jwt_decode(response.data.accessToken);
      setUser({
        ...user, // Copy other fields
        id_participante: decoded.id_participante,
        id_tipo_acceso: decoded.id_tipo_acceso
      });
      config.params = {
        id_participante: decoded.id_participante
      }
      setExpire(decoded.exp);
    } else {
      config.headers.Authorization = `Bearer ${token}`;
      config.params = {
        id_participante: user.id_participante
      }
    }
    return config;
  }, (error) => {
    return Promise.reject(error);
  });

  return (
    <div>
      {user.id_tipo_acceso === 1 ? (
        <NavbarAdmin />
      ) : (
        <NavbarUser />
      )}
    </div>
  );
}

export default HomeAdminScreen;
