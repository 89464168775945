import React, { useState, useEffect } from 'react';
import { server } from '../../config';
import axios from 'axios';
import jwt_decode from "jwt-decode";
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';
import { Form } from 'react-bootstrap';
import NavbarAdmin from "../Navbar/NavbarAdmin";

import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

function EditarParticipante() {
    const [nombre, setNombre] = useState(null);
    const [apellidos, setApellidos] = useState(null);
    const [empresa, setEmpresa] = useState(null);
    const [email, setEmail] = useState(null);
    const [movil, setMovil] = useState(null);
    const [observaciones, setObservaciones] = useState(null);
    const [id_mediodia, setIdMediodia] = useState(null);
    const [id_tipo_acceso, setIdTipoAcceso] = useState(null);
    const [id_talla_camiseta, setIdTallaCamiseta] = useState(null);
    const [id_equipo, setIdEquipo] = useState(null);
    const [id_frecuencia, setIdFrecuencia] = useState(null);
    const [mediodia, setMediodia] = useState([]);
    const [tipo_acceso, setTipoAcceso] = useState([]);
    const [talla_camiseta, setTallaCamiseta] = useState([]);
    const [equipos, setEquipos] = useState([]);
    const [frecuencia, setFrecuencia] = useState([]);

    const { id_participante } = useParams();
    const [user, setUser] = useState({
        userId: -1,
        name: ''
    });
    const [token, setToken] = useState('');
    const [expire, setExpire] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        document.body.classList.add("participantes-page");
        refreshToken();
        buscarParticipante();
        listarEquipos();
        listarFrecuencias();
        listarMediodia();
        listarTallaCamiseta();
        listarTiposAcceso();
    }, []);

    const refreshToken = async () => {
        try {
            const response = await axios.get('http://' + server + '/refresh');
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            setUser({
                ...user, // Copy other fields
                userId: decoded.userId,
                name: decoded.name,
                id_tipo_acceso: decoded.id_tipo_acceso
            });
            if (decoded.id_tipo_acceso === 2) {
                navigate('/homeUser');
            }
            setExpire(decoded.exp);
        } catch (error) {
            if (error.response) {
                navigate("/");
            }
        }
    }

    const axiosJWT = axios.create();

    // Siempre que se realice una peticion segura se ejcuta esta
    // funcion que actualiza el accessToken si es necesario
    // y en config añade los headers y los datos para las queries
    axiosJWT.interceptors.request.use(async (config) => {
        const currentDate = new Date();
        if (expire * 1000 < currentDate.getTime() || expire === undefined) {
            const response = await axios.get('http://' + server + '/refresh');
            config.headers.Authorization = `Bearer ${response.data.accessToken}`;
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            setUser({
                ...user, // Copy other fields
                userId: decoded.userId,
                name: decoded.name,
                id_tipo_acceso: decoded.id_tipo_acceso
            });
            config.params = {
                userId: decoded.userId
            }
            setExpire(decoded.exp);
        } else {
            config.headers.Authorization = `Bearer ${token}`;
            config.params = {
                userId: user.userId
            }
        }
        return config;
    }, (error) => {
        return Promise.reject(error);
    });

    const listarEquipos = async () => {
        try {
            const response = await axiosJWT.get('http://' + server + '/equipos');
            //console.log(response.data);
            setEquipos(response.data.results);
        } catch (err) {
            console.log(err);
        }
    };

    const listarFrecuencias = async () => {
        try {
            const response = await axiosJWT.get('http://' + server + '/frecuencia');
            //console.log(response.data);
            setFrecuencia(response.data.results);
        } catch (err) {
            console.log(err);
        }
    };

    const listarTiposAcceso = async () => {
        try {
            const response = await axiosJWT.get('http://' + server + '/tipoAcceso');
            //console.log(response.data);
            setTipoAcceso(response.data.results);
        } catch (err) {
            console.log(err);
        }
    };

    const listarTallaCamiseta = async () => {
        try {
            const response = await axiosJWT.get('http://' + server + '/tallaCamiseta');
            //console.log(response.data);
            setTallaCamiseta(response.data.results);
        } catch (err) {
            console.log(err);
        }
    };

    const listarMediodia = async () => {
        try {
            const response = await axiosJWT.get('http://' + server + '/mediodia');
            //console.log(response.data);
            setMediodia(response.data.results);
        } catch (err) {
            console.log(err);
        }
    };

    const buscarParticipante = async () => {
        try {
            const response = await axiosJWT.post('http://' + server + '/buscarParticipante', {
                params: {
                    id_participante: id_participante
                }
            });
            setNombre(response.data.results[0].nombre);
            setApellidos(response.data.results[0].apellidos);
            setEmpresa(response.data.results[0].empresa);
            setEmail(response.data.results[0].email);
            setMovil(response.data.results[0].movil);
            setObservaciones(response.data.results[0].observaciones);
            setIdMediodia(response.data.results[0].id_mediodia);
            setIdTipoAcceso(response.data.results[0].id_tipo_acceso);
            setIdTallaCamiseta(response.data.results[0].id_talla_camiseta);
            setIdEquipo(response.data.results[0].id_equipo);
            setIdFrecuencia(response.data.results[0].id_frecuencia);
            // Puedes continuar con los demás campos
        } catch (error) {
            console.error('Error al buscar participante:', error);
        }
    };

    const editarParticipante = async (e) => {
        e.preventDefault();
        try {
            // Lógica para realizar la petición de edición del participante
            await axiosJWT.post('http://' + server + '/editarParticipante', {
                nombre, apellidos, empresa, email, movil, observaciones, id_mediodia, id_tipo_acceso, id_talla_camiseta, id_equipo, id_frecuencia, id_participante
            });

            // Si la edición es exitosa, puedes mostrar un mensaje de éxito y redirigir
            EditadoCorrectamente();
        } catch (error) {
            // Manejo de errores, puedes mostrar un mensaje de error
            console.error('Error al editar participante:', error.message);
            ErrorEditando();
        }
    };

    const EditadoCorrectamente = () => {
        confirmAlert({
            title: 'Participante editado',
            message: '¡El participante ha sido editado con éxito!',
            buttons: [
                {
                    label: 'Cerrar',
                    onClick: () => navigate('/participantes')
                }
            ]
        });
    };

    const ErrorEditando = () => {
        confirmAlert({
            title: '¡Error!',
            message: 'Ha ocurrido un error y la operación no se ha podido llevar a cabo. Vuelve a intentarlo más tarde.',
            buttons: [
                {
                    label: 'Cerrar',
                    onClick: () => navigate('/participantes')
                }
            ]
        });
    };

    const cancelarEdicion = () => {
        navigate('/participantes');
    };

    return (
        <>
            <NavbarAdmin />
            <div className='p-5 text-center'>
                <h1 className='mb-3' style={{ fontSize: 30, fontWeight: 'bold' }}>Editar Participante</h1>
            </div>
            <form className="container-fluid" onSubmit={editarParticipante}>
                <div>
                    <div className="mb-3">
                        <Form.Label>Nombre</Form.Label>
                        <Form.Control required type="text" name="nombre" value={nombre} onChange={(e) => setNombre(e.target.value)} className="form-control form-control-lg" />
                    </div>
                    <div className="mb-3">
                        <Form.Label>Apellidos</Form.Label>
                        <Form.Control required type="text" name="apellidos" value={apellidos} onChange={(e) => setApellidos(e.target.value)} className="form-control form-control-lg" />
                    </div>
                    <div className="mb-3">
                        <Form.Label>Empresa</Form.Label>
                        <Form.Control type="text" name="empresa" value={empresa} onChange={(e) => setEmpresa(e.target.value)} className="form-control form-control-lg" />
                    </div>
                    <div className="mb-3">
                        <Form.Label>Email</Form.Label>
                        <Form.Control required type="email" name="email" value={email} onChange={(e) => setEmail(e.target.value)} className="form-control form-control-lg" />
                    </div>
                    <div className="mb-3">
                        <Form.Label>Móvil</Form.Label>
                        <Form.Control type="tel" name="movil" value={movil} onChange={(e) => setMovil(e.target.value)} className="form-control form-control-lg" />
                    </div>
                    <div className="mb-3">
                        <Form.Label>Observaciones</Form.Label>
                        <Form.Control as="textarea" name="observaciones" value={observaciones} onChange={(e) => setObservaciones(e.target.value)} className="form-control form-control-lg" />
                    </div>
                    <div className="mb-3">
                        <Form.Label>Mediodía</Form.Label>
                        <Form.Control as="select" name="id_mediodia" value={id_mediodia} onChange={(e) => setIdMediodia(e.target.value)} className="form-control form-control-lg">
                            {mediodia.map((item) => (
                                <option key={item.id_mediodia} value={item.id_mediodia}>{item.tipo}</option>
                            ))}
                        </Form.Control>
                    </div>
                    <div className="mb-3">
                        <Form.Label>Tipo de Acceso</Form.Label>
                        <Form.Control as="select" name="id_tipo_acceso" value={id_tipo_acceso} onChange={(e) => setIdTipoAcceso(e.target.value)} className="form-control form-control-lg">
                            {tipo_acceso.map((item) => (
                                <option key={item.id_tipo_acceso} value={item.id_tipo_acceso}>{item.tipo}</option>
                            ))}
                        </Form.Control>
                    </div>
                    <div className="mb-3">
                        <Form.Label>Talla de Camiseta</Form.Label>
                        <Form.Control as="select" name="id_talla_camiseta" value={id_talla_camiseta} onChange={(e) => setIdTallaCamiseta(e.target.value)} className="form-control form-control-lg">
                            {talla_camiseta.map((item) => (
                                <option key={item.id_talla_camiseta} value={item.id_talla_camiseta}>{item.talla}</option>
                            ))}
                        </Form.Control>
                    </div>
                    <div className="mb-3">
                        <Form.Label>Equipo</Form.Label>
                        <Form.Control as="select" name="id_equipo" value={id_equipo} onChange={(e) => setIdEquipo(e.target.value)} className="form-control form-control-lg">
                            {equipos.map((item) => (
                                <option key={item.id_equipo} value={item.id_equipo}>{item.nombre}</option>
                            ))}
                        </Form.Control>
                    </div>
                    <div className="mb-3">
                        <Form.Label>Frecuencia</Form.Label>
                        <Form.Control as="select" name="id_frecuencia" value={id_frecuencia} onChange={(e) => setIdFrecuencia(e.target.value)} className="form-control form-control-lg">
                        {frecuencia.map((item) => (
                                <option key={item.id_frecuencia} value={item.id_frecuencia}>{item.frecuencia}</option>
                            ))}
                        </Form.Control>
                    </div>
                    <div className="mb-3 text-center">
                        <button type="submit" className="btn btn-success btn-lg">Editar</button>{" "}
                        <button type="button" className="btn btn-danger btn-lg" onClick={cancelarEdicion}>Cancelar</button>
                    </div>
                </div>
            </form>
        </>
    );
}

export default EditarParticipante;
