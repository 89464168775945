import React, { useState, useEffect } from 'react';
import axios from 'axios';
import jwt_decode from 'jwt-decode';
import { server } from '../../config';
import { useNavigate } from 'react-router-dom';
import NavbarAdmin from '../Navbar/NavbarAdmin';
import './participantes-style.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

const GeneradorCalendario = () => {
  const [user, setUser] = useState({
    userId: -1,
    name: '',
});
const [token, setToken] = useState('');
const [expire, setExpire] = useState('');

const navigate = useNavigate();

useEffect(() => {
    document.body.classList.add("participantes-page");
    refreshToken();
}, []);

const refreshToken = async () => {
    try {
        const response = await axios.get('http://' + server + '/refresh');
        setToken(response.data.accessToken);
        const decoded = jwt_decode(response.data.accessToken);
        setUser({
            ...user, // Copy other fields
            userId: decoded.userId,
            name: decoded.name,
            id_tipo_acceso: decoded.id_tipo_acceso
        });
        if (decoded.id_tipo_acceso === 2) {
            navigate('/homeUser');
        }
        setExpire(decoded.exp);
    } catch (error) {
        if (error.response) {
            navigate('/');
        }
    }
};

const axiosJWT = axios.create();

axiosJWT.interceptors.request.use(
    async (config) => {
        const currentDate = new Date();
        if (expire * 1000 < currentDate.getTime() || expire === undefined) {
            const response = await axios.get('http://' + server + '/refresh');
            config.headers.Authorization = `Bearer ${response.data.accessToken}`;
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            setUser({
                ...user, // Copy other fields
                userId: decoded.userId,
                name: decoded.name,
                id_tipo_acceso: decoded.id_tipo_acceso
            });
            config.params = {
                userId: decoded.userId,
            };
            setExpire(decoded.exp);
        } else {
            config.headers.Authorization = `Bearer ${token}`;
            config.params = {
                userId: user.userId,
            };
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

  const handleGenerarCalendario = async () => {
    try {
      const response = await axiosJWT.post('http://' + server + '/generarCalendario');
      navigate("/partidos")
    } catch (error) {
      console.error('Error al generar el calendario:', error.message);
    }
  };

  return (
    <>
        <NavbarAdmin />
        <div>
            <h2>Generador de Calendario</h2>
            <button onClick={handleGenerarCalendario}>Generar Calendario</button>
        </div>
    </>
  );
};

export default GeneradorCalendario;
