import React, { useState, useEffect } from 'react';
import { server } from '../../config';
import axios from 'axios';
import jwt_decode from "jwt-decode";
import { useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';
import { Form } from 'react-bootstrap';
import NavbarAdmin from "../Navbar/NavbarAdmin";

import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

function InsertarEquipo() {
    const [nombre, setNombre] = useState(null);
    const [partidos_jugados, setPartidosJugados] = useState(null);
    const [partidos_ganados, setPartidosGanados] = useState(null);
    const [partidos_empatados, setPartidosEmpatados] = useState(null);
    const [partidos_perdidos, setPartidosPerdidos] = useState(null);
    const [observaciones, setObservaciones] = useState(null);
    const [puntos, setPuntos] = useState(null);
    const [id_liga, setIdLiga] = useState(null);
    const [liga, setLiga] = useState([]);
    const [id_nivel, setIdNivel] = useState(null);
    const [nivel, setNivel] = useState([]);
    const [id_grupo, setIdGrupo] = useState(null);
    const [grupo, setGrupo] = useState([]);
    const [user, setUser] = useState({
        userId: -1,
        name: ''
    });
    const [token, setToken] = useState('');
    const [expire, setExpire] = useState('');

    const navigate = useNavigate();

    useEffect(() => {
        document.body.classList.add("participantes-page");
        refreshToken();
        listarLiga();
        listarNivel();
        listarGrupo();
    }, [navigate]);

    const refreshToken = async () => {
        try {
            const response = await axios.get('http://' + server + '/refresh');
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            setUser({
                ...user, // Copy other fields
                userId: decoded.userId,
                name: decoded.name,
                id_tipo_acceso: decoded.id_tipo_acceso
            });
            if (decoded.id_tipo_acceso === 2) {
                navigate('/homeUser');
            }
            setExpire(decoded.exp);
        } catch (error) {
            if (error.response) {
                navigate("/");
            }
        }
    }

    const axiosJWT = axios.create();

    // Siempre que se realice una peticion segura se ejcuta esta
    // funcion que actualiza el accessToken si es necesario
    // y en config añade los headers y los datos para las queries
    axiosJWT.interceptors.request.use(async (config) => {
        const currentDate = new Date();
        if (expire * 1000 < currentDate.getTime() || expire === undefined) {
            const response = await axios.get('http://' + server + '/refresh');
            config.headers.Authorization = `Bearer ${response.data.accessToken}`;
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            setUser({
                ...user, // Copy other fields
                userId: decoded.userId,
                name: decoded.name,
                id_tipo_acceso: decoded.id_tipo_acceso
            });
            config.params = {
                userId: decoded.userId
            }
            setExpire(decoded.exp);
        } else {
            config.headers.Authorization = `Bearer ${token}`;
            config.params = {
                userId: user.userId
            }
        }
        return config;
    }, (error) => {
        return Promise.reject(error);
    });

    const listarLiga = async () => {
        try {
            const response = await axiosJWT.get('http://' + server + '/ligas');
            //console.log(response.data);
            setLiga(response.data.results);
        } catch (err) {
            console.log(err);
        }
    };

    const listarNivel = async () => {
        try {
            const response = await axiosJWT.get('http://' + server + '/niveles');
            //console.log(response.data);
            setNivel(response.data.results);
        } catch (err) {
            console.log(err);
        }
    };

    const listarGrupo = async () => {
        try {
            const response = await axiosJWT.get('http://' + server + '/grupos');
            //console.log(response.data);
            setGrupo(response.data.results);
        } catch (err) {
            console.log(err);
        }
    };

    const insertarEquipo = async (e) => {
        e.preventDefault();
        try {
            await axiosJWT.post('http://' + server + '/insertarEquipo',
                {
                    nombre: nombre, partidos_jugados: partidos_jugados, partidos_ganados: partidos_ganados, partidos_empatados: partidos_empatados,
                    partidos_perdidos: partidos_perdidos, puntos: puntos, observaciones: observaciones, id_liga: id_liga,
                    id_nivel: id_nivel, id_grupo: id_grupo
                });
            SuccessfullyAdded();
        } catch (error) {
            if (error.response) {
                console.log(error.response);
            }
            ErrorAdded();
        }
    };

    const SuccessfullyAdded = () => {
        confirmAlert({
            title: 'Equipo añadido',
            message: '¡El equipo ha sido añadido con éxito!',
            buttons: [
                {
                    label: 'Cerrar',
                    onClick: () => navigate("/equipos")
                }
            ]
        });
    }

    const ErrorAdded = () => {
        confirmAlert({
            title: '¡Error!',
            message: 'Ha ocurrido un error y la operación no se ha podido llevar a cabo. Vuelve a intentarlo más tarde.',
            buttons: [
                {
                    label: 'Cerrar',
                    onClick: () => window.location.reload()
                }
            ]
        });
    }

    return (
        <>
            <NavbarAdmin />
            <div className='p-5 text-center'>
                <h1 className='mb-3' style={{ fontSize: 30, fontWeight: 'bold' }}>Insertar Equipo</h1>
            </div>
            <form className="container-fluid" onSubmit={insertarEquipo}>
                <div>
                    <div className="mb-3">
                        <Form.Label>Nombre</Form.Label>
                        <Form.Control required type="text" name="nombre" value={nombre} onChange={(e) => setNombre(e.target.value)} className="form-control form-control-lg" />
                    </div>
                    <div className="mb-3">
                        <Form.Label>Partidos Jugados</Form.Label>
                        <Form.Control required type="number" name="partidos_jugados" value={partidos_jugados} onChange={(e) => setPartidosJugados(e.target.value)} className="form-control form-control-lg" />
                    </div>
                    <div className="mb-3">
                        <Form.Label>Partidos Ganados</Form.Label>
                        <Form.Control required type="number" name="partidos_ganados" value={partidos_ganados} onChange={(e) => setPartidosGanados(e.target.value)} className="form-control form-control-lg" />
                    </div>
                    <div className="mb-3">
                        <Form.Label>Partidos Empatados</Form.Label>
                        <Form.Control required type="number" name="partidos_empatados" value={partidos_empatados} onChange={(e) => setPartidosEmpatados(e.target.value)} className="form-control form-control-lg" />
                    </div>
                    <div className="mb-3">
                        <Form.Label>Partidos Perdidos</Form.Label>
                        <Form.Control required type="number" name="partidos_perdidos" value={partidos_perdidos} onChange={(e) => setPartidosPerdidos(e.target.value)} className="form-control form-control-lg" />
                    </div>
                    <div className="mb-3">
                        <Form.Label>Puntos</Form.Label>
                        <Form.Control required type="number" name="puntos" value={puntos} onChange={(e) => setPuntos(e.target.value)} className="form-control form-control-lg" />
                    </div>
                    <div className="mb-3">
                        <Form.Label>Observaciones</Form.Label>
                        <Form.Control required type="textarea" name="observaciones" value={observaciones} onChange={(e) => setObservaciones(e.target.value)} className="form-control form-control-lg" />
                    </div>
                    <div className="mb-3">
                        <Form.Label>Liga</Form.Label>
                        <Form.Control required as="select" name="liga" value={id_liga} onChange={(e) => setIdLiga(e.target.value)} className="form-control form-control-lg">
                            <option value="">Selecciona liga</option>
                            {liga.map((item) => (
                                <option key={item.id_liga} value={item.id_liga}>{item.anyo}</option>
                            ))}
                        </Form.Control>
                    </div>
                    <div className="mb-3">
                        <Form.Label>Nivel</Form.Label>
                        <Form.Control required as="select" name="nivel" value={id_nivel} onChange={(e) => setIdNivel(e.target.value)} className="form-control form-control-lg">
                            <option value="">Selecciona nivel</option>
                            {nivel.map((item) => (
                                <option key={item.id_nivel} value={item.id_nivel}>{item.tipo}</option>
                            ))}
                        </Form.Control>
                    </div>
                    <div className="mb-3">
                        <Form.Label>Grupo</Form.Label>
                        <Form.Control required as="select" name="grupo" value={id_grupo} onChange={(e) => setIdGrupo(e.target.value)} className="form-control form-control-lg">
                            <option value="">Selecciona grupo</option>
                            {grupo.map((item) => (
                                <option key={item.id_grupo} value={item.id_grupo}>{item.nombre}</option>
                            ))}
                        </Form.Control>
                    </div>
                    <div className="mb-3 text-center">
                        <button type="submit" className="btn btn-success btn-lg">Insertar</button>
                    </div>
                </div>
            </form>
        </>
    );

}

export default InsertarEquipo;
