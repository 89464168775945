import React, { useState, useEffect } from 'react';
import { server } from '../../config';
import axios from 'axios';
import jwt_decode from "jwt-decode";
import { useNavigate } from 'react-router-dom';
import NavbarAdmin from "../Navbar/NavbarAdmin";
import NavbarUser from "../Navbar/NavbarUser";
import './participantes-style.css';
import 'bootstrap/dist/css/bootstrap.css';

import '@fortawesome/fontawesome-free/css/all.min.css';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

function ProximosPartidos() {
    const [partidos, setPartidos] = useState(null);
    const [jornada, setJornada] = useState({});
    const [lugar, setLugar] = useState({});
    const [user, setUser] = useState({
        userId: -1,
        name: ''
    });
    const [token, setToken] = useState('');
    const [expire, setExpire] = useState('');

    const navigate = useNavigate();

    useEffect(() => {
        document.body.classList.add("participantes-page");
        refreshToken();
        listarPartidos();
        listarJornada();
        listarLugar();
    }, []);

    const refreshToken = async () => {
        try {
            const response = await axios.get('http://' + server + '/refresh');
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            setUser({
                ...user, // Copy other fields
                userId: decoded.userId,
                name: decoded.name,
                id_tipo_acceso: decoded.id_tipo_acceso
            });
            setExpire(decoded.exp);
        } catch (error) {
            if (error.response) {
                navigate("/");
            }
        }
    }

    const axiosJWT = axios.create();

    // Siempre que se realice una peticion segura se ejcuta esta
    // funcion que actualiza el accessToken si es necesario
    // y en config añade los headers y los datos para las queries
    axiosJWT.interceptors.request.use(async (config) => {
        const currentDate = new Date();
        if (expire * 1000 < currentDate.getTime() || expire === undefined) {
            const response = await axios.get('http://' + server + '/refresh');
            config.headers.Authorization = `Bearer ${response.data.accessToken}`;
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            setUser({
                ...user, // Copy other fields
                userId: decoded.userId,
                name: decoded.name,
                id_tipo_acceso: decoded.id_tipo_acceso
            });
            config.params = {
                userId: decoded.userId
            }
            setExpire(decoded.exp);
        } else {
            config.headers.Authorization = `Bearer ${token}`;
            config.params = {
                userId: user.userId
            }
        }
        return config;
    }, (error) => {
        return Promise.reject(error);
    });

    const formatDate = (dateString) => {
        if (!dateString) {
            return '';
        }
        const date = new Date(dateString);
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();
        return `${day < 10 ? '0' + day : day}/${month < 10 ? '0' + month : month}/${year}`;
    };
    
    const listarPartidos = async () => {
        try {
            const response = await axiosJWT.get('http://' + server + '/partidosParticipante');
            const partidosData = response.data;
            const formattedPartidos = await Promise.all(
                partidosData.results.map(async (partido) => {
                    // Obtener el nombre del Equipo 1
                    let equipo1Nombre = '';
                    // Obtener el nombre del Equipo 2
                    let equipo2Nombre = '';
                    try {
                        const equiposResponse = await axiosJWT.post('http://' + server + '/buscarEquiposPartido', {id_partido: partido.id_partido});
                        equipo1Nombre = equiposResponse.data.results[0].equipo1_nombre || '';
                        equipo2Nombre = equiposResponse.data.results[0].equipo2_nombre || '';
                    } catch (error) {
                        console.log(error);
                    }

                    return {
                        ...partido,
                        fecha: formatDate(partido.fecha),
                        equipo1Nombre,
                        equipo2Nombre,
                    };
                })
            );
            setPartidos({ ...partidosData, results: formattedPartidos });
        } catch (err) {
            console.log(err);
        }
    };



    const listarJornada = async () => {
        try {
            const response = await axiosJWT.get('http://' + server + '/jornadas');
            //console.log(response.data);
            const jornadaArray = response.data.results;
            const newJornada = {};
            jornadaArray.forEach((item) => {
                newJornada[item.id_jornada] = item.nombre;
            });
            setJornada(newJornada);
        } catch (err) {
            console.log(err);
        }
    };

    const listarLugar = async () => {
        try {
            const response = await axiosJWT.get('http://' + server + '/lugares');
            //console.log(response.data);
            const lugarArray = response.data.results;
            const newLugar = {};
            lugarArray.forEach((item) => {
                newLugar[item.id_lugar] = item.club;
            });
            setLugar(newLugar);
        } catch (err) {
            console.log(err);
        }
    };

    return (
        <>
            <div>
                {user.id_tipo_acceso === 1 ? (
                    <NavbarAdmin />
                ) : (
                    <NavbarUser />
                )}
            </div>
            {partidos && partidos.results ? (
                <table>
                    <thead>
                        <tr style={{ textAlign: "center" }}>
                            <th style={{ textAlign: "center" }}>Fecha</th>
                            <th style={{ textAlign: "center" }}>Observaciones</th>
                            <th style={{ textAlign: "center" }}>Equipo 1</th>
                            <th style={{ textAlign: "center" }}>Equipo 2</th>
                            <th style={{ textAlign: "center" }}>Jornada</th>
                            <th style={{ textAlign: "center" }}>Club</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Object.values(partidos.results).map((partido, index) => (
                            <tr key={index}>
                                <td style={{ textAlign: "center" }}>{partido.fecha}</td>
                                <td style={{ textAlign: "center" }}>{partido.observaciones}</td>
                                <td style={{ textAlign: "center" }}>{partido.equipo1Nombre}</td>
                                <td style={{ textAlign: "center" }}>{partido.equipo2Nombre}</td>
                                <td style={{ textAlign: "center" }}>{jornada[partido.id_jornada]}</td>
                                <td style={{ textAlign: "center" }}>{lugar[partido.id_lugar]}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            ) : (
                <div>Cargando datos...</div>
            )}
        </>
    );

}

export default ProximosPartidos;
