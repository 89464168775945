import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Navbar, Nav, NavDropdown, Image } from "react-bootstrap";
import './navbar-style.css';

function NavbarAdmin() {
  return (
    <Navbar bg="dark" variant="dark" expand="lg">
      <Navbar.Brand href="/homeAdmin">
        <Image src={require("../Login/images/logo-gama.png")} alt="Logo" className="logoNav" />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mr-auto">
          <Nav.Link href="/homeAdmin" style={{ color: "white" }}>Inicio</Nav.Link>
          <Nav.Link href="/proximosPartidos" style={{ color: "white" }}>Mis próximos partidos</Nav.Link>
          <NavDropdown title="Grupo Alto" style={{ color: "white" }} id="basic-nav-dropdown">
            <NavDropdown.Item href="/clasificacionAlto">Clasificacion</NavDropdown.Item>
            <NavDropdown.Item href="/partidosAlto">Partidos</NavDropdown.Item>
            <NavDropdown.Item href="/resultadosAlto">Resultados</NavDropdown.Item>
          </NavDropdown>
          <NavDropdown title="Grupo Medio 1" style={{ color: "white" }} id="basic-nav-dropdown">
            <NavDropdown.Item href="/clasificacionMedio1">Clasificacion</NavDropdown.Item>
            <NavDropdown.Item href="/partidosMedio1">Partidos</NavDropdown.Item>
            <NavDropdown.Item href="/resultadosMedio1">Resultados</NavDropdown.Item>
          </NavDropdown>
          <NavDropdown title="Grupo Medio 2" style={{ color: "white" }} id="basic-nav-dropdown">
            <NavDropdown.Item href="/clasificacionMedio2">Clasificacion</NavDropdown.Item>
            <NavDropdown.Item href="/partidosMedio2">Partidos</NavDropdown.Item>
            <NavDropdown.Item href="/resultadosMedio2">Resultados</NavDropdown.Item>
          </NavDropdown>
          <NavDropdown title="Grupo Bajo" style={{ color: "white" }} id="basic-nav-dropdown">
            <NavDropdown.Item href="/clasificacionBajo1">Clasificacion</NavDropdown.Item>
            <NavDropdown.Item href="/partidosBajo1">Partidos</NavDropdown.Item>
            <NavDropdown.Item href="/resultadosBajo1">Resultados</NavDropdown.Item>
          </NavDropdown>
          {/* <NavDropdown title="Grupo Bajo 2" style={{ color: "white" }} id="basic-nav-dropdown">
            <NavDropdown.Item href="/clasificacionBajo2">Clasificacion</NavDropdown.Item>
            <NavDropdown.Item href="/partidosBajo2">Partidos</NavDropdown.Item>
            <NavDropdown.Item href="/resultadosBajo2">Resultados</NavDropdown.Item>
          </NavDropdown> */}
          <NavDropdown title="Insertar" id="basic-nav-dropdown" style={{ color: "white" }}>
            <NavDropdown.Item href="/insertarParticipante">Participantes</NavDropdown.Item>
            <NavDropdown.Item href="/insertarEquipo">Equipos</NavDropdown.Item>
            <NavDropdown.Item href="/insertarLiga">Ligas</NavDropdown.Item>
            <NavDropdown.Item href="/insertarLugar">Lugares</NavDropdown.Item>
            <NavDropdown.Item href="/insertarGrupo">Grupos</NavDropdown.Item>
            <NavDropdown.Item href="/insertarPartido">Partidos</NavDropdown.Item>
            <NavDropdown.Item href="/insertarJornada">Jornadas</NavDropdown.Item>
            <NavDropdown.Item href="/insertarResultado">Resultados</NavDropdown.Item>
            <NavDropdown.Item href="/insertarNivel">Niveles</NavDropdown.Item>
            <NavDropdown.Item href="/insertarFrecuencia">Frecuencias</NavDropdown.Item>
            <NavDropdown.Item href="/insertarMediodia">Mediodia</NavDropdown.Item>
            <NavDropdown.Item href="/insertarTipoAcceso">Tipos Acceso</NavDropdown.Item>
            <NavDropdown.Item href="/insertarTallaCamiseta">Tallas Camisetas</NavDropdown.Item>
          </NavDropdown>
          <NavDropdown title="Ver" id="basic-nav-dropdown" style={{ color: "white" }}>
            <NavDropdown.Item href="/partidosSinDefinir">Partidos sin definir</NavDropdown.Item>
            <NavDropdown.Item href="/participantes">Participantes</NavDropdown.Item>
            <NavDropdown.Item href="equipos">Equipos</NavDropdown.Item>
            <NavDropdown.Item href="/ligas">Ligas</NavDropdown.Item>
            <NavDropdown.Item href="/lugares">Lugares</NavDropdown.Item>
            <NavDropdown.Item href="/grupos">Grupos</NavDropdown.Item>
            <NavDropdown.Item href="/partidos">Partidos</NavDropdown.Item>
            <NavDropdown.Item href="/jornadas">Jornadas</NavDropdown.Item>
            <NavDropdown.Item href="/resultadosGenerales">Resultados</NavDropdown.Item>
            <NavDropdown.Item href="/niveles">Niveles</NavDropdown.Item>
            <NavDropdown.Item href="/frecuencias">Frecuencias</NavDropdown.Item>
            <NavDropdown.Item href="/mediodia">Mediodia</NavDropdown.Item>
            <NavDropdown.Item href="/tiposAcceso">Tipos Acceso</NavDropdown.Item>
            <NavDropdown.Item href="/tallaCamiseta">Tallas Camisetas</NavDropdown.Item>
          </NavDropdown>
          <NavDropdown title="Gestion" id="basic-nav-dropdown" style={{ color: "white" }}>
            <NavDropdown.Item href="/archivosExcel">Subir Archivos</NavDropdown.Item>
            <NavDropdown.Item href="/generadorCalendario">Generador Calendario</NavDropdown.Item>
          </NavDropdown>
          <Nav.Link href="/comunicarResultados" style={{ color: "white" }}>Comunicar Resultados</Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}

export default NavbarAdmin;
