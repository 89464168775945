import React, { useState, useEffect } from 'react';
import axios from 'axios';
import jwt_decode from 'jwt-decode';
import { server } from '../../config';
import { useNavigate } from 'react-router-dom';
import NavbarAdmin from '../Navbar/NavbarAdmin';
import NavbarUser from "../Navbar/NavbarUser";
import './participantes-style.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

function PartidosMedio2() {
    const [partidos, setPartidos] = useState([]);
    const [jornadas, setJornadas] = useState([]);
    const [selectedJornada, setSelectedJornada] = useState(1);
    const [lugar, setLugar] = useState({});
    const [user, setUser] = useState({
        userId: -1,
        name: '',
    });
    const [token, setToken] = useState('');
    const [expire, setExpire] = useState('');

    const navigate = useNavigate();

    useEffect(() => {
        document.body.classList.add("participantes-page");
        refreshToken();
        listarPartidos();
        listarJornada();
        listarLugar();
    }, []);

    const refreshToken = async () => {
        try {
            const response = await axios.get('http://' + server + '/refresh');
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            setUser({
                ...user, // Copy other fields
                userId: decoded.userId,
                name: decoded.name,
                id_tipo_acceso: decoded.id_tipo_acceso
            });
            setExpire(decoded.exp);
        } catch (error) {
            if (error.response) {
                navigate('/');
            }
        }
    };

    const axiosJWT = axios.create();

    axiosJWT.interceptors.request.use(
        async (config) => {
            const currentDate = new Date();
            if (expire * 1000 < currentDate.getTime() || expire === undefined) {
                const response = await axios.get('http://' + server + '/refresh');
                config.headers.Authorization = `Bearer ${response.data.accessToken}`;
                setToken(response.data.accessToken);
                const decoded = jwt_decode(response.data.accessToken);
                setUser({
                    ...user, // Copy other fields
                    userId: decoded.userId,
                    name: decoded.name,
                    id_tipo_acceso: decoded.id_tipo_acceso
                });
                config.params = {
                    userId: decoded.userId,
                };
                setExpire(decoded.exp);
            } else {
                config.headers.Authorization = `Bearer ${token}`;
                config.params = {
                    userId: user.userId,
                };
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );

    const formatDate = (dateString) => {
        if (!dateString) {
            return ''; 
        }
        const date = new Date(dateString);
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();
        return `${day < 10 ? '0' + day : day}/${month < 10 ? '0' + month : month}/${year}`;
    };

    const listarPartidos = async () => {
        try {
            const response = await axiosJWT.get('http://' + server + '/partidosMedio2');
            const partidosData = response.data;
            const formattedPartidos = await Promise.all(
                partidosData.results.map(async (partido) => {
                    try {
                        const equiposResponse = await axiosJWT.post('http://' + server + '/buscarEquiposPartido', { id_partido: partido.id_partido });
                        const equipo1Nombre = equiposResponse.data.results[0].equipo1_nombre || '';
                        const equipo2Nombre = equiposResponse.data.results[0].equipo2_nombre || '';
                        return {
                            fecha: formatDate(partido.fecha),
                            equipo1Nombre,
                            equipo2Nombre,
                            id_lugar: partido.id_lugar,
                        };
                    } catch (error) {
                        console.log(error);
                        return null;
                    }
                })
            );
            const filteredPartidos = formattedPartidos.filter((partido) => partido !== null);
            setPartidos(filteredPartidos);
        } catch (err) {
            console.log(err);
        }
    };

    const listarJornada = async () => {
        try {
            const response = await axiosJWT.get('http://' + server + '/jornadasGrupoMedio2');
            setJornadas(response.data.results);
        } catch (err) {
            console.log(err);
        }
    };

    const listarLugar = async () => {
        try {
            const response = await axiosJWT.get('http://' + server + '/lugares');
            //console.log(response.data);
            const lugarArray = response.data.results;
            const newLugar = {};
            lugarArray.forEach((item) => {
                newLugar[item.id_lugar] = item.club;
            });
            setLugar(newLugar);
        } catch (err) {
            console.log(err);
        }
    };

    const groupPartidosByFecha = () => {
        const partidosByFecha = {};
        partidos.forEach((partido) => {
            const { fecha, equipo1Nombre, equipo2Nombre, id_lugar } = partido;
            if (partidosByFecha[fecha]) {
                const id_lugar = lugar[partido.id_lugar];
                partidosByFecha[fecha].push({ equipo1Nombre, equipo2Nombre, id_lugar });
            } else {
                partidosByFecha[fecha] = [{ equipo1Nombre, equipo2Nombre, id_lugar }];
            }
        });
        return partidosByFecha;
    };

    const renderJornadas = () => {
        return jornadas.map((jornada) => (
            <button
                key={jornada.id_jornada}
                className={`btn btn-sm ${jornada.id_jornada === selectedJornada ? 'btn-primary' : 'btn-secondary'}`}
                onClick={() => handleJornadaSelection(jornada.id_jornada)}
            >
                {jornada.nombre}
            </button>
        ));
    };

    const handleJornadaSelection = async (jornadaId) => {
        setSelectedJornada(jornadaId);
        console.log(jornadaId);
        try {
            const response = await axiosJWT.post('http://' + server + '/partidosMedio2Jornadas', { id_jornada: jornadaId });
            const partidosData = response.data;
            const formattedPartidos = await Promise.all(
                partidosData.results.map(async (partido) => {
                    try {
                        const equiposResponse = await axiosJWT.post('http://' + server + '/buscarEquiposPartido', { id_partido: partido.id_partido });
                        const equipo1Nombre = equiposResponse.data.results[0].equipo1_nombre || '';
                        const equipo2Nombre = equiposResponse.data.results[0].equipo2_nombre || '';
                        return {
                            fecha: formatDate(partido.fecha),
                            equipo1Nombre,
                            equipo2Nombre,
                            id_lugar: partido.id_lugar,
                        };
                    } catch (error) {
                        console.log(error);
                        return null;
                    }
                })
            );
            const filteredPartidos = formattedPartidos.filter((partido) => partido !== null);
            setPartidos(filteredPartidos);
        } catch (err) {
            console.log(err);
        }
    };

    const renderPartidosByFecha = () => {
        const partidosByFecha = groupPartidosByFecha();
        return Object.entries(partidosByFecha).map(([fecha, enfrentamientos], index) => (
            <div key={index}>
                <h5>{fecha}</h5>
                {enfrentamientos.map((enfrentamiento, subIndex) => (
                    <p key={subIndex}>{enfrentamiento.equipo1Nombre} VS {enfrentamiento.equipo2Nombre} - Club: {lugar[enfrentamiento.id_lugar]}</p>
                ))}
            </div>
        ));
    };

    return (
        <>
            <div>
                {user.id_tipo_acceso === 1 ? (
                    <NavbarAdmin />
                ) : (
                    <NavbarUser />
                )}
            </div>
            <div className="text-center mt-1">
                {jornadas.length > 0 && (
                    <div className="btn-group" role="group">
                        {renderJornadas()}
                    </div>
                )}
                <div className="mt-4">
                    {partidos.length > 0 ? (
                        renderPartidosByFecha()
                    ) : (
                        <div className="text-center">Cargando datos...</div>
                    )}
                </div>
            </div>
        </>
    );
}

export default PartidosMedio2;
