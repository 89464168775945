import React, { useState, useEffect } from 'react';
import axios from 'axios';
import jwt_decode from "jwt-decode";
import { server } from '../../config';
import Dropzone from 'react-dropzone';
import { useNavigate } from 'react-router-dom';
import NavbarAdmin from "../Navbar/NavbarAdmin";
import 'bootstrap/dist/css/bootstrap.css';
import { confirmAlert } from 'react-confirm-alert'; // Import

function ArchivosExcel() {
    const [file1, setFile1] = useState(null);
    const [file2, setFile2] = useState(null);
    const [user, setUser] = useState({
        userId: -1,
        name: ''
    });
    const [token, setToken] = useState('');
    const [expire, setExpire] = useState('');

    const navigate = useNavigate();

    useEffect(() => {
        document.body.classList.add("participantes-page");
        refreshToken();
    }, []);

    const refreshToken = async () => {
        try {
            const response = await axios.get('http://' + server + '/refresh');
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            setUser({
                ...user, // Copy other fields
                userId: decoded.userId,
                name: decoded.name,
                id_tipo_acceso: decoded.id_tipo_acceso
            });
            if (decoded.id_tipo_acceso === 2) {
                navigate('/homeUser');
            }
            setExpire(decoded.exp);
        } catch (error) {
            if (error.response) {
                navigate("/");
            }
        }
    }

    const axiosJWT = axios.create();

    axiosJWT.interceptors.request.use(async (config) => {
        const currentDate = new Date();
        if (expire * 1000 < currentDate.getTime() || expire === undefined) {
            const response = await axios.get('http://' + server + '/refresh');
            config.headers.Authorization = `Bearer ${response.data.accessToken}`;
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            setUser({
                ...user, // Copy other fields
                userId: decoded.userId,
                name: decoded.name,
                id_tipo_acceso: decoded.id_tipo_acceso
            });
            config.params = {
                userId: decoded.userId
            }
            setExpire(decoded.exp);
        } else {
            config.headers.Authorization = `Bearer ${token}`;
            config.params = {
                userId: user.userId
            }
        }
        return config;
    }, (error) => {
        return Promise.reject(error);
    });

    const handleDrop1 = (acceptedFiles) => {
        setFile1(acceptedFiles[0]);
    };

    const handleDrop2 = (acceptedFiles) => {
        setFile2(acceptedFiles[0]);
    };

    const handleSubmit1 = async () => {
        const formData = new FormData();
        formData.append('file', file1);
        try {
            await axios.post('http://' + server + '/excelEquipos', formData);
            SuccessfullyAddedEquipos();
        } catch (error) {
            ErrorAdded();
        }
    };

    const handleSubmit2 = async () => {
        const formData = new FormData();
        formData.append('file', file2);
        try {
            await axios.post('http://' + server + '/excelParticipantes', formData);
            SuccessfullyAddedParticipantes();
        } catch (error) {
            ErrorAdded();
        }
    };

    const SuccessfullyAddedEquipos = () => {
        confirmAlert({
            title: 'Equipos añadidos',
            message: '¡Los equipos han sido añadidos con éxito!',
            buttons: [
                {
                    label: 'Cerrar',
                    onClick: () => navigate("/equipos")
                }
            ]
        });
    }

    const SuccessfullyAddedParticipantes = () => {
        confirmAlert({
            title: 'Participantes añadidos',
            message: '¡Los participantes han sido añadidos con éxito!',
            buttons: [
                {
                    label: 'Cerrar',
                    onClick: () => navigate("/participantes")
                }
            ]
        });
    }

    const ErrorAdded = () => {
        confirmAlert({
            title: '¡Error!',
            message: 'Ha ocurrido un error y la operación no se ha podido llevar a cabo. Vuelve a intentarlo más tarde.',
            buttons: [
                {
                    label: 'Cerrar',
                    onClick: () => window.location.reload()
                }
            ]
        });
    }

    return (
        <>
            <NavbarAdmin />
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', minHeight: '80vh' }}>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Dropzone onDrop={handleDrop1}>
                        {({ getRootProps, getInputProps }) => (
                            <div {...getRootProps()}>
                                <input {...getInputProps()} />
                                {file1 ? (
                                    <div>
                                        <p>Archivo seleccionado:</p>
                                        <p>{file1.name}</p>
                                    </div>
                                ) : (
                                    <p>Excel de equipos aquí, arrástralo o haz clic para seleccionar.</p>
                                )}
                            </div>
                        )}
                    </Dropzone>
                    <button onClick={handleSubmit1} disabled={!file1}>
                        Subir archivo
                    </button>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '20px' }}>
                    {/* Aquí va el segundo componente */}
                    <Dropzone onDrop={handleDrop2}>
                        {({ getRootProps, getInputProps }) => (
                            <div {...getRootProps()}>
                                <input {...getInputProps()} />
                                {file2 ? (
                                    <div>
                                        <p>Archivo seleccionado:</p>
                                        <p>{file2.name}</p>
                                    </div>
                                ) : (
                                    <p>Excel de participantes aquí, arrástralo o haz clic para seleccionar.</p>
                                )}
                            </div>
                        )}
                    </Dropzone>
                    <button onClick={handleSubmit2} disabled={!file2}>
                        Subir archivo
                    </button>
                </div>
            </div>
        </>
    );
}

export default ArchivosExcel;
