import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import { server } from '../../config';
import jwt_decode from "jwt-decode";
import './login-style.css';

function LoginScreen() {
    const [email, setEmail] = useState('');
    const [contrasena, setContrasena] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        document.body.classList.add("login-page");
        return () => {
            document.body.classList.remove("login-page");
        };
    }, []);

    const handleSubmit = async (event) => {
        console.log(event);
        event.preventDefault();
        try {
            const response = await axios.post('http://' + server + '/login', { email: email, contrasena: contrasena });
            const decoded = jwt_decode(response.data.accessToken);
            if (decoded.primer_login === 1) {
                //navigate(`/cambiarContrasena/${decoded.id_participante}`);
                navigate('/cambiarContrasena', { state: { id_participante: decoded.id_participante } });
            } else if (decoded.id_tipo_acceso === 1) {
                navigate('/homeAdmin');
            } else {
                navigate('/homeUser');
            }
        } catch (error) {
            console.log(error);
	    if (error.response) {
                // The server responded with a status code outside the 2xx range
                console.log('Error response:', error.response);
            } else if (error.request) {
                // The request was made but no response was received
                console.log('Error request:', error.request);
            } else {
                // Something happened in setting up the request that triggered an error
                console.log('Error message:', error.message);
            }
        }
    };

    return (
        <div className="container">
            <div className="header">
                <img src={require("./images/logo-gama.png")} alt="Logo" className="logo" />
            </div>
            <div className="card" style={{ textAlign: "center" }}>
                <form onSubmit={handleSubmit}>
                    <div>Email
                        <input type="text" placeholder="Email" id="email" value={email} onChange={(event) => setEmail(event.target.value)} />
                    </div>
                    <div>
                        <input type="password" placeholder="Contraseña" id="contrasena" value={contrasena} onChange={(event) => setContrasena(event.target.value)} />
                    </div>
                    <button type="submit">Iniciar sesión</button>
                </form>
            </div>
        </div>
    );
}

export default LoginScreen;
