import React, { useState, useEffect } from 'react';
import axios from 'axios';
import jwt_decode from "jwt-decode";
import { server } from '../../config';
import { useNavigate } from 'react-router-dom';
import NavbarAdmin from "../Navbar/NavbarAdmin";
import NavbarUser from "../Navbar/NavbarUser";
import 'bootstrap/dist/css/bootstrap.css';
import './participantes-style.css';

function ClasificacionAlto() {
    const [equipos, setEquipos] = useState(null);
    const [user, setUser] = useState({
        userId: -1,
        name: ''
    });
    const [token, setToken] = useState('');
    const [expire, setExpire] = useState('');

    const navigate = useNavigate();

    useEffect(() => {
        document.body.classList.add("participantes-page");
        refreshToken();
        listarEquipos();
    }, []);

    const refreshToken = async () => {
        try {
            const response = await axios.get('http://' + server + '/refresh');
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            setUser({
                ...user, // Copy other fields
                userId: decoded.userId,
                name: decoded.name,
                id_tipo_acceso: decoded.id_tipo_acceso
            });
            setExpire(decoded.exp);
        } catch (error) {
            if (error.response) {
                navigate("/");
            }
        }
    }

    const axiosJWT = axios.create();

    axiosJWT.interceptors.request.use(async (config) => {
        const currentDate = new Date();
        if (expire * 1000 < currentDate.getTime() || expire === undefined) {
            const response = await axios.get('http://' + server + '/refresh');
            config.headers.Authorization = `Bearer ${response.data.accessToken}`;
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            setUser({
                ...user, // Copy other fields
                userId: decoded.userId,
                name: decoded.name,
                id_tipo_acceso: decoded.id_tipo_acceso
            });
            config.params = {
                userId: decoded.userId
            }
            setExpire(decoded.exp);
        } else {
            config.headers.Authorization = `Bearer ${token}`;
            config.params = {
                userId: user.userId
            }
        }
        return config;
    }, (error) => {
        return Promise.reject(error);
    });

    const listarEquipos = async () => {
        try {
            const response = await axiosJWT.get('http://' + server + '/clasificacionAlto');
            setEquipos(response.data);
        } catch (err) {
            console.log(err);
        }
    };

    return (
        <>
            <div>
                {user.id_tipo_acceso === 1 ? (
                    <NavbarAdmin />
                ) : (
                    <NavbarUser />
                )}
            </div>
            {equipos ? (
                <table>
                    <thead>
                        <tr>
                            <th style={{ textAlign: "center" }}>Posición</th>
                            <th style={{ textAlign: "center" }}>Nombre</th>
                            <th style={{ textAlign: "center" }}>PJ</th>
                            <th style={{ textAlign: "center" }}>PG</th>
                            <th style={{ textAlign: "center" }}>PE</th>
                            <th style={{ textAlign: "center" }}>PP</th>
                            <th style={{ textAlign: "center" }}>Puntos</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Object.values(equipos.results).map((equipo, index) => (
                            <tr key={index}>
                                <td style={{ textAlign: "center" }}>{index + 1}</td>
                                <td style={{ textAlign: "center" }}>{equipo.nombre}</td>
                                <td style={{ textAlign: "center" }}>{equipo.partidos_jugados}</td>
                                <td style={{ textAlign: "center" }}>{equipo.partidos_ganados}</td>
                                <td style={{ textAlign: "center" }}>{equipo.partidos_empatados}</td>
                                <td style={{ textAlign: "center" }}>{equipo.partidos_perdidos}</td>
                                <td style={{ textAlign: "center" }}>{equipo.puntos}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            ) : (
                <div>Cargando datos...</div>
            )}
        </>
    );
}

export default ClasificacionAlto;
