import React, { useState, useEffect } from 'react';
import axios from 'axios';
import jwt_decode from 'jwt-decode';
import { useNavigate } from 'react-router-dom';
import { server } from '../../config';
import NavbarAdmin from '../Navbar/NavbarAdmin';
import NavbarUser from "../Navbar/NavbarUser";
import './participantes-style.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

function ResultadosBajo1() {
    const [partidos, setPartidos] = useState([]);
    const [jornadas, setJornadas] = useState([]);
    const [selectedJornada, setSelectedJornada] = useState(1);
    const [user, setUser] = useState({
        userId: -1,
        name: '',
    });
    const [token, setToken] = useState('');
    const [expire, setExpire] = useState('');

    const navigate = useNavigate();

    useEffect(() => {
        document.body.classList.add("participantes-page");
        refreshToken();
        listarPartidos();
        listarJornada();
    }, []);

    const refreshToken = async () => {
        try {
            const response = await axios.get('http://' + server + '/refresh');
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            setUser({
                ...user, // Copy other fields
                userId: decoded.userId,
                name: decoded.name,
                id_tipo_acceso: decoded.id_tipo_acceso
            });
            setExpire(decoded.exp);
        } catch (error) {
            if (error.response) {
                navigate('/');
            }
        }
    };

    const axiosJWT = axios.create();

    axiosJWT.interceptors.request.use(
        async (config) => {
            const currentDate = new Date();
            if (expire * 1000 < currentDate.getTime() || expire === undefined) {
                const response = await axios.get('http://' + server + '/refresh');
                config.headers.Authorization = `Bearer ${response.data.accessToken}`;
                setToken(response.data.accessToken);
                const decoded = jwt_decode(response.data.accessToken);
                setUser({
                    ...user, // Copy other fields
                    userId: decoded.userId,
                    name: decoded.name,
                    id_tipo_acceso: decoded.id_tipo_acceso
                });
                config.params = {
                    userId: decoded.userId,
                };
                setExpire(decoded.exp);
            } else {
                config.headers.Authorization = `Bearer ${token}`;
                config.params = {
                    userId: user.userId,
                };
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );

    const formatDate = (dateString) => {
        if (!dateString) {
            return ''; 
        }
        const date = new Date(dateString);
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();
        return `${day < 10 ? '0' + day : day}/${month < 10 ? '0' + month : month}/${year}`;
    };

    const listarPartidos = async () => {
        try {
            const response = await axiosJWT.get('http://' + server + '/partidosBajo1');
            const partidosData = response.data;
            const formattedPartidos = await Promise.all(
                partidosData.results.map(async (partido) => {
                    try {
                        const equiposResponse = await axiosJWT.post('http://' + server + '/buscarEquiposPartido', { id_partido: partido.id_partido });
                        const equipo1Nombre = equiposResponse.data.results[0].equipo1_nombre || '';
                        const equipo1Id = equiposResponse.data.results[0].equipo1_id || '';
                        const equipo2Nombre = equiposResponse.data.results[0].equipo2_nombre || '';
                        const equipo2Id = equiposResponse.data.results[0].equipo2_id || '';
                        const resultadosResponse = await axiosJWT.post('http://' + server + '/resultadosGrupos', { id_equipo: equipo1Id, id_partido: partido.id_partido });
                        const equipo1Set1 = resultadosResponse.data.results.length > 0 ? resultadosResponse.data.results[0].puntos : '';
                        const equipo1Set2 = resultadosResponse.data.results.length > 1 ? resultadosResponse.data.results[1].puntos : '';
                        const equipo1Set3 = resultadosResponse.data.results.length > 2 ? resultadosResponse.data.results[2].puntos : '';

                        const resultados2Response = await axiosJWT.post('http://' + server + '/resultadosGrupos', { id_equipo: equipo2Id, id_partido: partido.id_partido });
                        const equipo2Set1 = resultados2Response.data.results.length > 0 ? resultados2Response.data.results[0].puntos : '';
                        const equipo2Set2 = resultados2Response.data.results.length > 1 ? resultados2Response.data.results[1].puntos : '';
                        const equipo2Set3 = resultados2Response.data.results.length > 2 ? resultados2Response.data.results[2].puntos : '';

                        return {
                            fecha: formatDate(partido.fecha),
                            equipo1Nombre,
                            equipo2Nombre,
                            equipo1Set1,
                            equipo1Set2,
                            equipo1Set3,
                            equipo2Set1,
                            equipo2Set2,
                            equipo2Set3,
                        };
                    } catch (error) {
                        console.log(error);
                        return null;
                    }
                })
            );
            const filteredPartidos = formattedPartidos.filter((partido) => partido !== null);
            setPartidos(filteredPartidos);
        } catch (err) {
            console.log(err);
        }
    };

    const listarJornada = async () => {
        try {
            const response = await axiosJWT.get('http://' + server + '/jornadasGrupoBajo1');
            setJornadas(response.data.results);
        } catch (err) {
            console.log(err);
        }
    };

    const groupPartidosByFecha = () => {
        const partidosByFecha = {};
        partidos.forEach((partido) => {
            const { fecha, equipo1Nombre, equipo2Nombre, equipo1Set1, equipo1Set2, equipo1Set3, equipo2Set1, equipo2Set2, equipo2Set3 } = partido;
            if (partidosByFecha[fecha]) {
                partidosByFecha[fecha].push({ equipo1Nombre, equipo2Nombre, equipo1Set1, equipo1Set2, equipo1Set3, equipo2Set1, equipo2Set2, equipo2Set3 });
            } else {
                partidosByFecha[fecha] = [{ equipo1Nombre, equipo2Nombre, equipo1Set1, equipo1Set2, equipo1Set3, equipo2Set1, equipo2Set2, equipo2Set3 }];
            }
        });
        return partidosByFecha;
    };

    const renderJornadas = () => {
        return jornadas.map((jornada) => (
            <button
                key={jornada.id_jornada}
                className={`btn btn-sm ${jornada.id_jornada === selectedJornada ? 'btn-primary' : 'btn-secondary'}`}
                onClick={() => handleJornadaSelection(jornada.id_jornada)}
            >
                {jornada.nombre}
            </button>
        ));
    };

    const handleJornadaSelection = async (jornadaId) => {
        setSelectedJornada(jornadaId);
        console.log(jornadaId);
        try {
            const response = await axiosJWT.post('http://' + server + '/partidosBajo1Jornadas', { id_jornada: jornadaId });
            const partidosData = response.data;
            const formattedPartidos = await Promise.all(
                partidosData.results.map(async (partido) => {
                    try {
                        const equiposResponse = await axiosJWT.post('http://' + server + '/buscarEquiposPartido', { id_partido: partido.id_partido });
                        const equipo1Nombre = equiposResponse.data.results[0].equipo1_nombre || '';
                        const equipo1Id = equiposResponse.data.results[0].equipo1_id || '';
                        const equipo2Nombre = equiposResponse.data.results[0].equipo2_nombre || '';
                        const equipo2Id = equiposResponse.data.results[0].equipo2_id || '';
                        const resultadosResponse = await axiosJWT.post('http://' + server + '/resultadosGrupos', { id_equipo: equipo1Id, id_partido: partido.id_partido });
                        const equipo1Set1 = resultadosResponse.data.results.length > 0 ? resultadosResponse.data.results[0].puntos : '';
                        const equipo1Set2 = resultadosResponse.data.results.length > 1 ? resultadosResponse.data.results[1].puntos : '';
                        const equipo1Set3 = resultadosResponse.data.results.length > 2 ? resultadosResponse.data.results[2].puntos : '';

                        const resultados2Response = await axiosJWT.post('http://' + server + '/resultadosGrupos', { id_equipo: equipo2Id, id_partido: partido.id_partido });
                        const equipo2Set1 = resultados2Response.data.results.length > 0 ? resultados2Response.data.results[0].puntos : '';
                        const equipo2Set2 = resultados2Response.data.results.length > 1 ? resultados2Response.data.results[1].puntos : '';
                        const equipo2Set3 = resultados2Response.data.results.length > 2 ? resultados2Response.data.results[2].puntos : '';

                        return {
                            fecha: formatDate(partido.fecha),
                            equipo1Nombre,
                            equipo2Nombre,
                            equipo1Set1,
                            equipo1Set2,
                            equipo1Set3,
                            equipo2Set1,
                            equipo2Set2,
                            equipo2Set3,
                        };
                    } catch (error) {
                        console.log(error);
                        return null;
                    }
                })
            );
            const filteredPartidos = formattedPartidos.filter((partido) => partido !== null);
            setPartidos(filteredPartidos);
        } catch (err) {
            console.log(err);
        }
    };

    const renderPartidosByFecha = () => {
        const partidosByFecha = groupPartidosByFecha();
        return Object.entries(partidosByFecha).map(([fecha, enfrentamientos], index) => (
            <div key={index}>
                <h5>{fecha}</h5>
                <div className="row no-gutters" style={{ borderBottom: "1px solid gray" }}>
                    <div className="row">
                        <h6 className="col">PARTIDO</h6>
                        <h6 className="col">SET 1</h6>
                        <h6 className="col">SET 2</h6>
                        <h6 className="col">SET 3</h6>
                    </div>
                </div>
                {enfrentamientos.map((enfrentamiento, subIndex) => (
                    <div key={subIndex} className="row no-gutters" style={{ borderBottom: "1px solid gray", backgroundColor: subIndex % 2 === 0 ? "#f5f5f5" : "white" }}>
                        <div className="row">
                            <p className="col">{enfrentamiento.equipo1Nombre}</p>
                            <p className={`col ${parseInt(enfrentamiento.equipo1Set1) > parseInt(enfrentamiento.equipo2Set1) ? "text-success" : "text-danger"}`}>
                                {enfrentamiento.equipo1Set1}
                            </p>
                            <p className={`col ${parseInt(enfrentamiento.equipo1Set2) > parseInt(enfrentamiento.equipo2Set2) ? "text-success" : "text-danger"}`}>
                                {enfrentamiento.equipo1Set2}
                            </p>
                            <p className={`col ${parseInt(enfrentamiento.equipo1Set3) > parseInt(enfrentamiento.equipo2Set3) ? "text-success" : "text-danger"}`}>
                                {enfrentamiento.equipo1Set3}
                            </p>
                        </div>
                        <div className="mb-2 row">
                            <span className="col">VS</span>
                            <span className="col">-</span>
                            <span className="col">-</span>
                            <span className="col">-</span>
                        </div>
                        <div className="row">
                            <p className="col">{enfrentamiento.equipo2Nombre}</p>
                            <p className={`col ${parseInt(enfrentamiento.equipo2Set1) > parseInt(enfrentamiento.equipo1Set1) ? "text-success" : "text-danger"}`}>
                                {enfrentamiento.equipo2Set1}
                            </p>
                            <p className={`col ${parseInt(enfrentamiento.equipo2Set2) > parseInt(enfrentamiento.equipo1Set2) ? "text-success" : "text-danger"}`}>
                                {enfrentamiento.equipo2Set2}
                            </p>
                            <p className={`col ${parseInt(enfrentamiento.equipo2Set3) > parseInt(enfrentamiento.equipo1Set3) ? "text-success" : "text-danger"}`}>
                                {enfrentamiento.equipo2Set3}
                            </p>
                        </div>
                    </div>
                ))}
            </div>
        ));
    };

    return (
        <>
            <div>
                {user.id_tipo_acceso === 1 ? (
                    <NavbarAdmin />
                ) : (
                    <NavbarUser />
                )}
            </div>
            <div className="text-center mt-1">
                {jornadas.length > 0 && (
                    <div className="btn-group" role="group">
                        {renderJornadas()}
                    </div>
                )}
                <div className="mt-4">
                    {partidos.length > 0 ? (
                        renderPartidosByFecha()
                    ) : (
                        <div className="text-center">Cargando datos...</div>
                    )}
                </div>
            </div>
        </>
    );
}

export default ResultadosBajo1;
