import React, { useState, useEffect } from 'react';
import axios from 'axios';
import jwt_decode from 'jwt-decode';
import { server } from '../../config';
import { useNavigate, useParams } from 'react-router-dom';
import NavbarAdmin from '../Navbar/NavbarAdmin';
import NavbarUser from "../Navbar/NavbarUser";
import 'bootstrap/dist/css/bootstrap.css';

function InsertarComunicacionResultados() {
    const [equipo1, setEquipo1] = useState(null);
    const [idEquipo1, setIdEquipo1] = useState(null);
    const [equipo1Set1, setEquipo1Set1] = useState(null);
    const [equipo1Set2, setEquipo1Set2] = useState(null);
    const [equipo1Set3, setEquipo1Set3] = useState(null);
    const [equipo2, setEquipo2] = useState(null);
    const [idEquipo2, setIdEquipo2] = useState(null);
    const [equipo2Set1, setEquipo2Set1] = useState(null);
    const [equipo2Set2, setEquipo2Set2] = useState(null);
    const [equipo2Set3, setEquipo2Set3] = useState(null);
    const [idGanador, setIdGanador] = useState(null);
    const { id_partido } = useParams();
    const [user, setUser] = useState({
        userId: -1,
        name: '',
    });
    const [token, setToken] = useState('');
    const [expire, setExpire] = useState('');

    const navigate = useNavigate();

    useEffect(() => {
        document.body.classList.add("participantes-page");
        refreshToken();
        listarEquipos();
    }, []);

    const refreshToken = async () => {
        try {
            const response = await axios.get('http://' + server + '/refresh');
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            setUser({
                ...user, // Copy other fields
                userId: decoded.userId,
                name: decoded.name,
                id_tipo_acceso: decoded.id_tipo_acceso
            });
            setExpire(decoded.exp);
        } catch (error) {
            if (error.response) {
                navigate('/');
            }
        }
    };

    const axiosJWT = axios.create();

    axiosJWT.interceptors.request.use(
        async (config) => {
            const currentDate = new Date();
            if (expire * 1000 < currentDate.getTime() || expire === undefined) {
                const response = await axios.get('http://' + server + '/refresh');
                config.headers.Authorization = `Bearer ${response.data.accessToken}`;
                setToken(response.data.accessToken);
                const decoded = jwt_decode(response.data.accessToken);
                setUser({
                    ...user, // Copy other fields
                    userId: decoded.userId,
                    name: decoded.name,
                    id_tipo_acceso: decoded.id_tipo_acceso
                });
                config.params = {
                    userId: decoded.userId,
                };
                setExpire(decoded.exp);
            } else {
                config.headers.Authorization = `Bearer ${token}`;
                config.params = {
                    userId: user.userId,
                };
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );

    const listarEquipos = async () => {
        try {
            const equiposResponse = await axiosJWT.post('http://' + server + '/buscarEquiposPartido', { id_partido });
            setEquipo1(equiposResponse.data.results[0].equipo1_nombre || '');
            setEquipo2(equiposResponse.data.results[0].equipo2_nombre || '');
            setIdEquipo1(equiposResponse.data.results[0].equipo1_id || '');
            setIdEquipo2(equiposResponse.data.results[0].equipo2_id || '');
        } catch (err) {
            console.log(err);
        }
    };

    const insertarResultado = async (e) => {
        e.preventDefault();
        try {
            await axiosJWT.post('http://' + server + '/comunicarResultado', {
                set1_local: equipo1Set1,
                set2_local: equipo1Set2,
                set3_local: equipo1Set3,
                id_local: idEquipo1,
                set1_visitante: equipo2Set1,
                set2_visitante: equipo2Set2,
                set3_visitante: equipo2Set3,
                id_visitante: idEquipo2,
                id_partido,
                id_ganador: idGanador,
            });
            navigate("/comunicarResultados")
        } catch (error) {
            if (error.response) {
                console.log(error.response);
            }
        }
    };

    return (
        <>
            <div>
                {user.id_tipo_acceso === 1 ? (
                    <NavbarAdmin />
                ) : (
                    <NavbarUser />
                )}
            </div>
            <div className="container-fluid">
                <div className="row text-center">
                    <div className="col form-group">
                        <label></label>
                        <h5>{equipo1}</h5>
                    </div>
                    <div className="col form-group">
                        <label htmlFor="equipo1Set1">SET 1</label>
                        <input
                            id="equipo1Set1"
                            className="form-control form-control-sm"
                            type="number"
                            style={{ width: '70px', margin: '0 auto' }}
                            value={equipo1Set1}
                            onChange={(e) => {
                                const inputValue = e.target.value;
                                if (inputValue.length <= 1) {
                                    setEquipo1Set1(inputValue);
                                }
                            }}
                            min="0"
                            max="7"
                            onKeyDown={(e) => {
                                const key = e.key;
                                if (key !== "Backspace" && (key < "0" || key > "7")) {
                                    e.preventDefault();
                                }
                            }}
                        />
                    </div>
                    <div className="col form-group">
                        <label htmlFor="equipo1Set2">SET 2</label>
                        <input
                            id="equipo1Set2"
                            className="form-control form-control-sm"
                            type="number"
                            style={{ width: '70px', margin: '0 auto' }}
                            value={equipo1Set2}
                            onChange={(e) => {
                                const inputValue = e.target.value;
                                if (inputValue.length <= 1) {
                                    setEquipo1Set2(inputValue);
                                }
                            }}
                            min="0"
                            max="7"
                            onKeyDown={(e) => {
                                const key = e.key;
                                if (key !== "Backspace" && (key < "0" || key > "7")) {
                                    e.preventDefault();
                                }
                            }}
                        />
                    </div>
                    <div className="col form-group">
                        <label htmlFor="equipo1Set3">SET 3</label>
                        <input
                            id="equipo1Set3"
                            className="form-control form-control-sm"
                            type="number"
                            style={{ width: '70px', margin: '0 auto' }}
                            value={equipo1Set3}
                            onChange={(e) => {
                                const inputValue = e.target.value;
                                if (inputValue.length <= 1) {
                                    setEquipo1Set3(inputValue);
                                }
                            }}
                            min="0"
                            max="7"
                            onKeyDown={(e) => {
                                const key = e.key;
                                if (key !== "Backspace" && (key < "0" || key > "7")) {
                                    e.preventDefault();
                                }
                            }}
                            required
                        />
                    </div>
                </div>
                <div className="row text-center">
                    <div className="col form-group">
                        <label></label>
                        <h5>{equipo2}</h5>
                    </div>
                    <div className="col form-group">
                        <label htmlFor="equipo2Set1">SET 1</label>
                        <input
                            id="equipo2Set1"
                            className="form-control form-control-sm"
                            type="number"
                            style={{ width: '70px', margin: '0 auto' }}
                            value={equipo2Set1}
                            onChange={(e) => {
                                const inputValue = e.target.value;
                                if (inputValue.length <= 1) {
                                    setEquipo2Set1(inputValue);
                                }
                            }}
                            min="0"
                            max="7"
                            onKeyDown={(e) => {
                                const key = e.key;
                                if (key !== "Backspace" && (key < "0" || key > "7")) {
                                    e.preventDefault();
                                }
                            }}
                        />
                    </div>
                    <div className="col form-group">
                        <label htmlFor="equipo2Set2">SET 2</label>
                        <input
                            id="equipo2Set2"
                            className="form-control form-control-sm"
                            type="number"
                            style={{ width: '70px', margin: '0 auto' }}
                            value={equipo2Set2}
                            onChange={(e) => {
                                const inputValue = e.target.value;
                                if (inputValue.length <= 1) {
                                    setEquipo2Set2(inputValue);
                                }
                            }}
                            min="0"
                            max="7"
                            onKeyDown={(e) => {
                                const key = e.key;
                                if (key !== "Backspace" && (key < "0" || key > "7")) {
                                    e.preventDefault();
                                }
                            }}
                        />
                    </div>
                    <div className="col form-group">
                        <label htmlFor="equipo2Set3">SET 3</label>
                        <input
                            id="equipo2Set3"
                            className="form-control form-control-sm"
                            type="number"
                            style={{ width: '70px', margin: '0 auto' }}
                            value={equipo2Set3}
                            onChange={(e) => {
                                const inputValue = e.target.value;
                                if (inputValue.length <= 1) {
                                    setEquipo2Set3(inputValue);
                                }
                            }}
                            min="0"
                            max="7"
                            onKeyDown={(e) => {
                                const key = e.key;
                                if (key !== "Backspace" && (key < "0" || key > "7")) {
                                    e.preventDefault();
                                }
                            }}
                            required
                        />
                    </div>
                </div>
            </div>
            <div className="text-center mt-4">
                <label htmlFor="ganador">Seleccionar Ganador:</label>
                <select
                    id="ganador"
                    className="form-control"
                    value={idGanador}
                    onChange={(e) => setIdGanador(e.target.value)}
                >
                    <option value="">Seleccionar</option>
                    <option value={idEquipo1}>{equipo1}</option>
                    <option value={idEquipo2}>{equipo2}</option>
                    <option value="">Empate</option>
                </select>
                <button className="btn btn-primary" onClick={insertarResultado}>
                    Guardar
                </button>
            </div>
        </>
    );
}

export default InsertarComunicacionResultados;
