import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Navbar, Nav, NavDropdown, Image } from "react-bootstrap";
import './navbar-style.css';

function NavbarUser() {
  return (
    <Navbar bg="dark" variant="dark" expand="lg">
      <Navbar.Brand href="/homeUser">
        <Image src={require("../Login/images/logo-gama.png")} alt="Logo" className="logoNav" />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mr-auto">
          <Nav.Link href="/homeUser" style={{ color: "white" }}>Inicio</Nav.Link>
          <Nav.Link href="/proximosPartidos" style={{ color: "white" }}>Mis próximos partidos</Nav.Link>
          <NavDropdown title="Grupo Alto" style={{ color: "white" }} id="basic-nav-dropdown">
            <NavDropdown.Item href="/clasificacionAlto">Clasificacion</NavDropdown.Item>
            <NavDropdown.Item href="/partidosAlto">Partidos</NavDropdown.Item>
            <NavDropdown.Item href="/resultadosAlto">Resultados</NavDropdown.Item>
          </NavDropdown>
          <NavDropdown title="Grupo Medio 1" style={{ color: "white" }} id="basic-nav-dropdown">
            <NavDropdown.Item href="/clasificacionMedio1">Clasificacion</NavDropdown.Item>
            <NavDropdown.Item href="/partidosMedio1">Partidos</NavDropdown.Item>
            <NavDropdown.Item href="/resultadosMedio1">Resultados</NavDropdown.Item>
          </NavDropdown>
          <NavDropdown title="Grupo Medio 2" style={{ color: "white" }} id="basic-nav-dropdown">
            <NavDropdown.Item href="/clasificacionMedio2">Clasificacion</NavDropdown.Item>
            <NavDropdown.Item href="/partidosMedio2">Partidos</NavDropdown.Item>
            <NavDropdown.Item href="/resultadosMedio2">Resultados</NavDropdown.Item>
          </NavDropdown>
          <NavDropdown title="Grupo Bajo" style={{ color: "white" }} id="basic-nav-dropdown">
            <NavDropdown.Item href="/clasificacionBajo1">Clasificacion</NavDropdown.Item>
            <NavDropdown.Item href="/partidosBajo1">Partidos</NavDropdown.Item>
            <NavDropdown.Item href="/resultadosBajo1">Resultados</NavDropdown.Item>
          </NavDropdown>
          {/* <NavDropdown title="Grupo Bajo 2" style={{ color: "white" }} id="basic-nav-dropdown">
            <NavDropdown.Item href="/clasificacionBajo2">Clasificacion</NavDropdown.Item>
            <NavDropdown.Item href="/partidosBajo2">Partidos</NavDropdown.Item>
            <NavDropdown.Item href="/resultadosBajo2">Resultados</NavDropdown.Item>
          </NavDropdown> */}
          <Nav.Link href="/comunicarResultados" style={{ color: "white" }}>Comunicar Resultados</Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}

export default NavbarUser;
