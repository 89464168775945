import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import { server } from '../../config';
import jwt_decode from "jwt-decode";
import { useLocation } from 'react-router-dom';
import '../Login/login-style.css';

function CambiarContrasenaScreen() {
    const [contrasena, setContrasena] = useState('');
    const [nuevaContrasena, setNuevaContrasena] = useState('');
    const location = useLocation();
    const { id_participante } = location.state;
    const [user, setUser] = useState({
        id_participante: -1,
        id_tipo_acceso: ''
      });
      const [token, setToken] = useState('');
      const [expire, setExpire] = useState('');
    
      const navigate = useNavigate();
    
      useEffect(() => {
        refreshToken();
        document.body.classList.add("bodyHomePage");
        return () => {
          document.body.classList.remove("bodyHomePage");
        };
      }, []);
    
      const refreshToken = async () => {
        try {
          const response = await axios.get('http://' + server + '/refresh');
          setToken(response.data.accessToken);
          const decoded = jwt_decode(response.data.accessToken);
          setUser({
            ...user, // Copy other fields
            id_participante: decoded.id_participante,
            id_tipo_acceso: decoded.id_tipo_acceso
          });
          setExpire(decoded.exp);
        } catch (error) {
          if (error.response) {
            navigate("/");
          }
        }
      }
    
      const axiosJWT = axios.create();
    
      // Siempre que se realice una peticion segura se ejcuta esta
      // funcion que actualiza el accessToken si es necesario
      // y en config añade los headers y los datos para las queries
      axiosJWT.interceptors.request.use(async (config) => {
        const currentDate = new Date();
        if (expire * 1000 < currentDate.getTime() || expire == undefined) {
          const response = await axios.get('http://' + server + '/refresh');
          config.headers.Authorization = `Bearer ${response.data.accessToken}`;
          setToken(response.data.accessToken);
          const decoded = jwt_decode(response.data.accessToken);
          setUser({
            ...user, // Copy other fields
            id_participante: decoded.id_participante,
            id_tipo_acceso: decoded.id_tipo_acceso
          });
          config.params = {
            id_participante: decoded.id_participante
          }
          setExpire(decoded.exp);
        } else {
          config.headers.Authorization = `Bearer ${token}`;
          config.params = {
            id_participante: user.id_participante
          }
        }
        return config;
      }, (error) => {
        return Promise.reject(error);
      });

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const response = await axiosJWT.post('http://' + server + '/cambiarContrasena', { contrasena: contrasena, nuevaContrasena: nuevaContrasena, id_participante: id_participante });
            navigate('/');
        } catch (err) {
            console.log(err);
        }
    };

    return (
        <div className="container">
            <div className="header">
                <img src={require("../Login/images/logo-gama.png")} alt="Logo" className="logo" />
            </div>
            <div className="card" style={{ textAlign: "center" }}>
                <form onSubmit={handleSubmit}>
                    <div>
                        <input type="password" placeholder="Contraseña" id="contrasena" value={contrasena} onChange={(event) => setContrasena(event.target.value)} />
                    </div>
                    <div>
                        <input type="password" placeholder="Confirma Contraseña" id="nuevaContrasena" value={nuevaContrasena} onChange={(event) => setNuevaContrasena(event.target.value)} />
                    </div>
                    <button type="submit">Cambiar contraseña</button>
                </form>
            </div>
        </div>
    );
}

export default CambiarContrasenaScreen;
