import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import ArchivosExcelScreen from './screens/Views/ArchivosExcel';
import LoginScreen from './screens/Login/LoginScreen';
import HomeAdminScreen from './screens/Home/HomeAdminScreen';
import HomeUserScreen from './screens/Home/HomeUserScreen';
import GeneradorCalendarioScreen from './screens/Views/GeneradorCalendario';

import ParticipantesScreen from './screens/Views/Participantes';
import TallasCamisetasScreen from './screens/Views/TallaCamiseta';
import FrecuenciasScreen from './screens/Views/Frecuencias';
import NivelesScreen from './screens/Views/Niveles';
import GruposScreen from './screens/Views/Grupos';
import JornadasScreen from './screens/Views/Jornadas';
import LigasScreen from './screens/Views/Ligas';
import LugaresScreen from './screens/Views/Lugares';
import MediodiaScreen from './screens/Views/Mediodia';
import TiposAccesoScreen from './screens/Views/TiposAcceso';
import EquiposScreen from './screens/Views/Equipos';
import PartidosScreen from './screens/Views/Partidos';
import ResultadosGeneralesScreen from './screens/Views/ResultadosGenerales';

import ClasificacionAltoScreen from './screens/Views/ClasificacionAlto';
import ClasificacionMedio1Screen from './screens/Views/ClasificacionMedio1';
import ClasificacionMedio2Screen from './screens/Views/ClasificacionMedio2';
import ClasificacionBajo1Screen from './screens/Views/ClasificacionBajo1';
import ClasificacionBajo2Screen from './screens/Views/ClasificacionBajo2';
import PartidosAltoScreen from './screens/Views/PartidosAlto';
import PartidosMedio1Screen from './screens/Views/PartidosMedio1';
import PartidosMedio2Screen from './screens/Views/PartidosMedio2';
import PartidosBajo1Screen from './screens/Views/PartidosBajo1';
import PartidosBajo2Screen from './screens/Views/PartidosBajo2';
import ResultadosAltoScreen from './screens/Views/ResultadosAlto';
import ResultadosMedio1Screen from './screens/Views/ResultadosMedio1';
import ResultadosMedio2Screen from './screens/Views/ResultadosMedio2';
import ResultadosBajo1Screen from './screens/Views/ResultadosBajo1';
import ResultadosBajo2Screen from './screens/Views/ResultadosBajo2';
import ComunicarResultadosScreen from './screens/Views/ComunicarResultados';

import InsertarTallasCamisetasScreen from './screens/Inserts/InsertarTallaCamiseta';
import InsertarFrecuenciaScreen from './screens/Inserts/InsertarFrecuencia';
import InsertarGrupoScreen from './screens/Inserts/InsertarGrupo';
import InsertarJornadaScreen from './screens/Inserts/InsertarJornada';
import InsertarLigaScreen from './screens/Inserts/InsertarLiga';
import InsertarLugarScreen from './screens/Inserts/InsertarLugar';
import InsertarMediodiaScreen from './screens/Inserts/InsertarMediodia';
import InsertarNivelScreen from './screens/Inserts/InsertarNivel';
import InsertarTipoAccesoScreen from './screens/Inserts/InsertarTipoAcceso';
import InsertarPartidoScreen from './screens/Inserts/InsertarPartido';
import InsertarEquipoScreen from './screens/Inserts/InsertarEquipo';
import InsertarParticipanteScreen from './screens/Inserts/InsertarParticipante';
import InsertarResultadoScreen from './screens/Inserts/InsertarResultado';
import InsertarComunicacionResultadoScreen from './screens/Inserts/InsertarComunicacionResultados';
import InsertarEquiposPartidoScreen from './screens/Inserts/InsertarEquiposPartido';

import EditarTallasCamisetasScreen from './screens/Edits/EditarTallaCamiseta';
import EditarFrecuenciaScreen from './screens/Edits/EditarFrecuencia';
import EditarGrupoScreen from './screens/Edits/EditarGrupo';
import EditarJornadaScreen from './screens/Edits/EditarJornada';
import EditarLigaScreen from './screens/Edits/EditarLiga';
import EditarLugarScreen from './screens/Edits/EditarLugar';
import EditarMediodiaScreen from './screens/Edits/EditarMediodia';
import EditarNivelScreen from './screens/Edits/EditarNivel';
import EditarTipoAccesoScreen from './screens/Edits/EditarTipoAcceso';
import EditarPartidoScreen from './screens/Edits/EditarPartido';
import EditarEquipoScreen from './screens/Edits/EditarEquipo';
import EditarParticipanteScreen from './screens/Edits/EditarParticipante';
import EditarResultadoScreen from './screens/Edits/EditarResultado';

import ProximosPartidosScreen from './screens/Views/ProximosPartidos';
import PartidosSinDefinirScreen from './screens/Views/PartidosSinDefinir';
import CambiarContrasenaScreen from './screens/Views/CambiarContrasena';

function Navigation() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<LoginScreen />} />
                <Route path="/homeAdmin" element={<HomeAdminScreen />} />
                <Route path="/homeUser" element={<HomeUserScreen />} />
                <Route path="/archivosExcel" element={<ArchivosExcelScreen />} />
                <Route path="/generadorCalendario" element={<GeneradorCalendarioScreen />} />

                <Route path="/participantes" element={<ParticipantesScreen />} />
                <Route path="/tallaCamiseta" element={<TallasCamisetasScreen />} />
                <Route path="/frecuencias" element={<FrecuenciasScreen />} />
                <Route path="/niveles" element={<NivelesScreen />} />
                <Route path="/grupos" element={<GruposScreen />} />
                <Route path="/jornadas" element={<JornadasScreen />} />
                <Route path="/ligas" element={<LigasScreen />} />
                <Route path="/lugares" element={<LugaresScreen />} />
                <Route path="/mediodia" element={<MediodiaScreen />} />
                <Route path="/tiposAcceso" element={<TiposAccesoScreen />} />
                <Route path="/equipos" element={<EquiposScreen />} />
                <Route path="/partidos" element={<PartidosScreen />} />
                <Route path="/resultadosGenerales" element={<ResultadosGeneralesScreen />} />

                <Route path="/clasificacionAlto" element={<ClasificacionAltoScreen />} />
                <Route path="/clasificacionMedio1" element={<ClasificacionMedio1Screen />} />
                <Route path="/clasificacionMedio2" element={<ClasificacionMedio2Screen />} />
                <Route path="/clasificacionBajo1" element={<ClasificacionBajo1Screen />} />
                <Route path="/clasificacionBajo2" element={<ClasificacionBajo2Screen />} />
                <Route path="/partidosAlto" element={<PartidosAltoScreen />} />
                <Route path="/partidosMedio1" element={<PartidosMedio1Screen />} />
                <Route path="/partidosMedio2" element={<PartidosMedio2Screen />} />
                <Route path="/partidosBajo1" element={<PartidosBajo1Screen />} />
                <Route path="/partidosBajo2" element={<PartidosBajo2Screen />} />
                <Route path="/resultadosAlto" element={<ResultadosAltoScreen />} />
                <Route path="/resultadosMedio1" element={<ResultadosMedio1Screen />} />
                <Route path="/resultadosMedio2" element={<ResultadosMedio2Screen />} />
                <Route path="/resultadosBajo1" element={<ResultadosBajo1Screen />} />
                <Route path="/resultadosBajo2" element={<ResultadosBajo2Screen />} />
                <Route path="/comunicarResultados" element={<ComunicarResultadosScreen />} />

                <Route path="/insertarTallaCamiseta" element={<InsertarTallasCamisetasScreen />} />
                <Route path="/insertarFrecuencia" element={<InsertarFrecuenciaScreen />} />
                <Route path="/insertarGrupo" element={<InsertarGrupoScreen />} />
                <Route path="/insertarJornada" element={<InsertarJornadaScreen />} />
                <Route path="/insertarLiga" element={<InsertarLigaScreen />} />
                <Route path="/insertarLugar" element={<InsertarLugarScreen />} />
                <Route path="/insertarMediodia" element={<InsertarMediodiaScreen />} />
                <Route path="/insertarNivel" element={<InsertarNivelScreen />} />
                <Route path="/insertarTipoAcceso" element={<InsertarTipoAccesoScreen />} />
                <Route path="/insertarPartido" element={<InsertarPartidoScreen />} />
                <Route path="/insertarEquipo" element={<InsertarEquipoScreen />} />
                <Route path="/insertarResultado" element={<InsertarResultadoScreen />} />
                <Route path="/insertarParticipante" element={<InsertarParticipanteScreen />} />
                <Route path="/insertarComunicacionResultados/:id_partido" element={<InsertarComunicacionResultadoScreen />} />

                <Route path="/insertarEquiposPartido/:id_partido" element={<InsertarEquiposPartidoScreen />} />

                <Route path="/editarTallaCamiseta/:id_talla_camiseta" element={<EditarTallasCamisetasScreen />} />
                <Route path="/editarFrecuencia/:id_frecuencia" element={<EditarFrecuenciaScreen />} />
                <Route path="/editarGrupo/:id_grupo" element={<EditarGrupoScreen />} />
                <Route path="/editarJornada/:id_jornada" element={<EditarJornadaScreen />} />
                <Route path="/editarLiga/:id_liga" element={<EditarLigaScreen />} />
                <Route path="/editarLugar/:id_lugar" element={<EditarLugarScreen />} />
                <Route path="/editarMediodia/:id_mediodia" element={<EditarMediodiaScreen />} />
                <Route path="/editarNivel/:id_nivel" element={<EditarNivelScreen />} />
                <Route path="/editarTipoAcceso/:id_tipo_acceso" element={<EditarTipoAccesoScreen />} />
                <Route path="/editarPartido/:id_partido" element={<EditarPartidoScreen />} />
                <Route path="/editarEquipo/:id_equipo" element={<EditarEquipoScreen />} />
                <Route path="/editarParticipante/:id_participante" element={<EditarParticipanteScreen />} />
                <Route path="/editarResultado/:id_resultado" element={<EditarResultadoScreen />} />
                <Route path="/proximosPartidos" element={<ProximosPartidosScreen />} />
                <Route path="/partidosSinDefinir" element={<PartidosSinDefinirScreen />} />
                <Route path="/cambiarContrasena" element={<CambiarContrasenaScreen />} />
            </Routes>
        </BrowserRouter>
    );
}

export default Navigation;
