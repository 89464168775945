import React, { useState, useEffect } from 'react';
import { server } from '../../config';
import axios from 'axios';
import jwt_decode from "jwt-decode";
import { useNavigate } from 'react-router-dom';
import NavbarAdmin from "../Navbar/NavbarAdmin";
import './participantes-style.css';
import 'bootstrap/dist/css/bootstrap.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import '@fortawesome/fontawesome-free/css/all.min.css';

import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

function ResultadosGenerales() {
    const [equipos, setEquipos] = useState({});
    const [resultados, setResultados] = useState(null);
    const [partidos, setPartidos] = useState({});
    const [user, setUser] = useState({
        userId: -1,
        name: ''
    });
    const [token, setToken] = useState('');
    const [expire, setExpire] = useState('');

    const navigate = useNavigate();

    useEffect(() => {
        document.body.classList.add("participantes-page");
        refreshToken();
        listarResultados();
        listarEquipos();
        listarPartido();
    }, []);

    const refreshToken = async () => {
        try {
            const response = await axios.get('http://' + server + '/refresh');
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            setUser({
                ...user, // Copy other fields
                userId: decoded.userId,
                name: decoded.name,
                id_tipo_acceso: decoded.id_tipo_acceso
            });
            if (decoded.id_tipo_acceso === 2) {
                navigate('/homeUser');
            }
            setExpire(decoded.exp);
        } catch (error) {
            if (error.response) {
                navigate("/");
            }
        }
    }

    const axiosJWT = axios.create();

    // Siempre que se realice una peticion segura se ejcuta esta
    // funcion que actualiza el accessToken si es necesario
    // y en config añade los headers y los datos para las queries
    axiosJWT.interceptors.request.use(async (config) => {
        const currentDate = new Date();
        if (expire * 1000 < currentDate.getTime() || expire === undefined) {
            const response = await axios.get('http://' + server + '/refresh');
            config.headers.Authorization = `Bearer ${response.data.accessToken}`;
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            setUser({
                ...user, // Copy other fields
                userId: decoded.userId,
                name: decoded.name,
                id_tipo_acceso: decoded.id_tipo_acceso
            });
            config.params = {
                userId: decoded.userId
            }
            setExpire(decoded.exp);
        } else {
            config.headers.Authorization = `Bearer ${token}`;
            config.params = {
                userId: user.userId
            }
        }
        return config;
    }, (error) => {
        return Promise.reject(error);
    });

    const listarResultados = async () => {
        try {
            const response = await axiosJWT.get('http://' + server + '/resultados');
            //console.log(response.data);
            setResultados(response.data);
        } catch (err) {
            console.log(err);
        }
    };

    const listarEquipos = async () => {
        try {
            const response = await axiosJWT.get('http://' + server + '/equipos');
            //console.log(response.data);
            const equipoArray = response.data.results;
            const newEquipo = {};
            equipoArray.forEach((item) => {
                newEquipo[item.id_equipo] = item.nombre;
            });
            setEquipos(newEquipo);
        } catch (err) {
            console.log(err);
        }
    };

    const formatDate = (dateString) => {
        if (!dateString) {
            return ''; 
        }
        const date = new Date(dateString);
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();
        return `${day < 10 ? '0' + day : day}/${month < 10 ? '0' + month : month}/${year}`;
    };

    const listarPartido = async () => {
        try {
            const response = await axiosJWT.get('http://' + server + '/partidos');
            //console.log(response.data);
            const partidoArray = response.data.results;
            const newPartido = {};
            partidoArray.forEach((item) => {
                newPartido[item.id_partido] = formatDate(item.fecha);
            });
            setPartidos(newPartido);
        } catch (err) {
            console.log(err);
        }
    };

    const eliminarResultado = async (id_resultado) => {
        try {
            await axiosJWT.post('http://' + server + '/eliminarResultado', { id_resultado: id_resultado });
            EliminadoCorrectamente();
        } catch (error) {
            if (error.response) {
                console.log(error.response);
            }
            ErrorEliminando();
        }
    };

    const EliminadoCorrectamente = () => {
        confirmAlert({
            title: 'Resultado Eliminado',
            message: '¡El resultado ha sido eliminado con éxito!',
            buttons: [
                {
                    label: 'Cerrar',
                    onClick: () => window.location.reload()
                }
            ]
        });
    }

    const ErrorEliminando = () => {
        confirmAlert({
            title: '¡Error!',
            message: 'Ha ocurrido un error y la operación no se ha podido llevar a cabo. Vuelve a intentarlo más tarde.',
            buttons: [
                {
                    label: 'Cerrar',
                    onClick: () => window.location.reload()
                }
            ]
        });
    }

    const AvisoEliminar = (id_resultado) => {
        confirmAlert({
            title: '¡CUIDADO!',
            message: '¿Seguro que quieres eliminar este elemento?',
            buttons: [
                {
                    label: 'Si',
                    onClick: () => eliminarResultado(id_resultado)
                },
                {
                    label: 'No',
                    onClick: () => window.location.reload()
                }
            ]
        });
    }

    return (
        <>
            <NavbarAdmin />
            {resultados ? (
                <table>
                    <thead>
                        <tr style={{ textAlign: "center" }}>
                            <th style={{ textAlign: "center" }}>Equipo Local</th>
                            <th style={{ textAlign: "center" }}>Puntos Local</th>
                            <th style={{ textAlign: "center" }}>Equipo Visitante</th>
                            <th style={{ textAlign: "center" }}>Puntos Visitante</th>
                            <th style={{ textAlign: "center" }}>Fecha</th>
                            <th style={{ textAlign: "center" }}>Observaciones</th>
                            <th style={{ textAlign: "center" }}>Acciones</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Object.values(resultados.results).map((resultado, index) => (
                            <tr key={index}>
                                <td style={{ textAlign: "center" }}>{equipos[resultado.id_local]}</td>
                                <td style={{ textAlign: "center" }}>{resultado.puntos_local}</td>
                                <td style={{ textAlign: "center" }}>{equipos[resultado.id_visitante]}</td>
                                <td style={{ textAlign: "center" }}>{resultado.puntos_visitante}</td>
                                <td style={{ textAlign: "center" }}>{partidos[resultado.id_partido]}</td>
                                <td style={{ textAlign: "center" }}>{resultado.observaciones}</td>
                                <td style={{ textAlign: "center" }}> 
                                    <button type="button" onClick={() => navigate(`/editarResultado/${resultado.id_resultado}`)} style={{ borderRadius: '20%' }}>
                                        <FontAwesomeIcon icon={faPencilAlt} />
                                    </button>{" "}
                                    <button type="button" onClick={() => AvisoEliminar(resultado.id_resultado)} style={{ borderRadius: '20%' }}>
                                        <FontAwesomeIcon icon={faTrashAlt} />
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            ) : (
                <div>Cargando datos...</div>
            )}
        </>
    );

}

export default ResultadosGenerales;
