import React, { useState, useEffect } from 'react';
import { server } from '../../config';
import axios from 'axios';
import jwt_decode from "jwt-decode";
import { useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';
import { Form } from 'react-bootstrap';
import NavbarAdmin from "../Navbar/NavbarAdmin";

import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

function InsertarLiga() {
    const [anyo, setAnyo] = useState(null);
    const [user, setUser] = useState({
        userId: -1,
        name: ''
    });
    const [token, setToken] = useState('');
    const [expire, setExpire] = useState('');

    const navigate = useNavigate();

    useEffect(() => {
        document.body.classList.add("participantes-page");
        refreshToken();
    },);

    const refreshToken = async () => {
        try {
            const response = await axios.get('http://' + server + '/refresh');
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            setUser({
                ...user, // Copy other fields
                userId: decoded.userId,
                name: decoded.name,
                id_tipo_acceso: decoded.id_tipo_acceso
            });
            if (decoded.id_tipo_acceso === 2) {
                navigate('/homeUser');
            }
            setExpire(decoded.exp);
        } catch (error) {
            if (error.response) {
                navigate("/");
            }
        }
    }

    const axiosJWT = axios.create();

    // Siempre que se realice una peticion segura se ejcuta esta
    // funcion que actualiza el accessToken si es necesario
    // y en config añade los headers y los datos para las queries
    axiosJWT.interceptors.request.use(async (config) => {
        const currentDate = new Date();
        if (expire * 1000 < currentDate.getTime() || expire === undefined) {
            const response = await axios.get('http://' + server + '/refresh');
            config.headers.Authorization = `Bearer ${response.data.accessToken}`;
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            setUser({
                ...user, // Copy other fields
                userId: decoded.userId,
                name: decoded.name,
                id_tipo_acceso: decoded.id_tipo_acceso
            });
            config.params = {
                userId: decoded.userId
            }
            setExpire(decoded.exp);
        } else {
            config.headers.Authorization = `Bearer ${token}`;
            config.params = {
                userId: user.userId
            }
        }
        return config;
    }, (error) => {
        return Promise.reject(error);
    });

    const insertarLiga = async (e) => {
        e.preventDefault();
        try {
            await axiosJWT.post('http://' + server + '/insertarLiga', { anyo: anyo });
            SuccessfullyAdded();
        } catch (error) {
            if (error.response) {
                console.log(error.response);
            }
            ErrorAdded();
        }
    };

    const SuccessfullyAdded = () => {
        confirmAlert({
            title: 'Liga añadida',
            message: '¡La liga ha sido añadida con éxito!',
            buttons: [
                {
                    label: 'Cerrar',
                    onClick: () => navigate("/ligas")
                }
            ]
        });
    }

    const ErrorAdded = () => {
        confirmAlert({
            title: '¡Error!',
            message: 'Ha ocurrido un error y la operación no se ha podido llevar a cabo. Vuelve a intentarlo más tarde.',
            buttons: [
                {
                    label: 'Cerrar',
                    onClick: () => window.location.reload()
                }
            ]
        });
    }

    return (
        <>
            <NavbarAdmin />
            <div className='p-5 text-center'>
                <h1 className='mb-3' style={{ fontSize: 30, fontWeight: 'bold' }}>Insertar Liga</h1>
            </div>
            <form className="container-fluid" onSubmit={insertarLiga}>
                <div>
                    <div className="mb-3">
                        <Form.Label>Año</Form.Label>
                        <Form.Control required type="name" name="anyo" value={anyo} onChange={(e) => setAnyo(e.target.value)} className="form-control form-control-lg" />
                    </div>
                    <div className="mb-3 text-center">
                        <button type="submit" className="btn btn-success btn-lg">Insertar</button>
                    </div>
                </div>
            </form>
        </>
    );

}

export default InsertarLiga;
